import { Box, Checkbox, styled } from '@mui/material'
import React from 'react'
import { type AccountUser, type CPTModifier } from '@/types'
import Form from '@rjsf/core'
import { getSubmitButtonOptions, type SubmitButtonProps } from '@rjsf/utils'
import validator from '@rjsf/validator-ajv8'
import { type ProcedureCPTCode } from '../../../../types/ProcedureCPTCode'
import { type ProcedureType } from '../../../../types/ProcedureType'
import { AddButton } from '../components/AddButton/AddButton'
import { PillSelect } from '../components/PillSelect'
import { TileInputLabel } from '../components/TileInputLabel'
import { TileSearchBox } from '../components/TileSearchBox'
import { TileTextArea } from '../components/TileTextArea'
import { TileTextField } from '../components/TileTextField'
import { Title } from '../components/Title'
import { AddTable } from '../layout/AddTable'
import { TileColumn } from '../layout/TileColumn'
import { TileLayout } from '../layout/TileLayout'
import { TileRow } from '../layout/TileRow'
import { TitleRow } from '../VitalsTile/styles'
import { ProcedureRow } from './ProcedureRow'
import { getClinicalProcedureFields } from '@/services'
import { useAuth } from '@clerk/nextjs'
import {
  generateSchemas,
  mapDTOToFormData,
  mapFormDataToDTO,
} from '@/helpers/procedure'
import './procedure.module.css'
import {
  CustomCheckbox,
  CustomDate,
  CustomRadio,
  CustomSelect,
  CustomTextbox,
  CustomDateTimePicker,
} from './CustomWidgets'
import { Shimmer } from '../Shimmer'
import {
  useDebounce,
  useMutateUpdateIndividualProcedure,
  useMutateUpdateProcedure,
  useProcedureStore,
} from '@/hook'
import { CustomPillSearch } from './CustomWidgets/CustomPillSearch'

const CheckBoxRow = styled('div')({
  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'center',
  marginTop: '-12px',
})

const CheckBoxContainer = styled('div')({
  display: 'flex',
  alignContent: 'center',
  alignItems: 'center',
  paddingTop: '24px',
})

const ButtonRow = styled('div')({
  display: 'flex',
  justifyContent: 'flex-end',
  width: '768px',
})
interface FilterProcedure {
  procedure: ProcedureCPTCode;
  index: number;
}
export const ProceduresTile = ({
  visitId,
  procedureTypes,
  ref,
  inFocus,
  setInFocus,
  accountUsers,
  isLocked = false,
  cptModifiers,
  setProcedureDeleted = () => {},
  procedureTypeSelected,
  setProcedureTypeSelected,
  isUpdating = false,
  templateUpdate,
  setTemplateUpdate,
}: ProceduresTileProps): JSX.Element => {
  const { getToken } = useAuth()
  const { procedure } = useProcedureStore()
  const [procedureCPTCodes, setProcedureCPTCodes] = React.useState<
    ProcedureCPTCode[]
  >([])
  const formRef = React.createRef<any>()
  const [formData, setFormData] = React.useState<any>({})
  const [jsonSchema, setJsonSchema] = React.useState<any>({})
  const [uiSchema, setUiSchema] = React.useState<any>({})
  const [details, setDetails] = React.useState<string>(procedure?.details ?? '')
  const debouncedDetails = useDebounce<string>(details, 400)
  const [results, setResults] = React.useState<string>('')
  const [filteredList, setFilteredList] = React.useState<FilterProcedure[]>([])
  const [viewProcedure, setViewProcedure] = React.useState<boolean>(false)
  const [selectedModifiers, setSelectedModifiers] = React.useState<number[]>(
    []
  )
  const [fields, setFields] = React.useState<any>([{}])

  const [status, setStatus] = React.useState<number>(1)
  const [cPTCode, setCPTCode] = React.useState<string>('')
  const updateProcedureMutation = useMutateUpdateProcedure(getToken)
  const editProcedureMutation = useMutateUpdateIndividualProcedure(getToken)
  const [isEdit, setIsEdit] = React.useState<boolean>(false)
  const [procedureId, setProcedureId] = React.useState<number>(0)

  React.useEffect(() => {
    setDetails(procedure?.details ?? '')
  }, [procedure?.details])

  const handleModifiersChange = (event: any): void => {
    const {
      target: { value },
    } = event
    setSelectedModifiers(value as number[])
  }
  const [clinicalProcedureFields, setClinicalProcedureFields] = React.useState(
    new Map()
  )

  React.useEffect(() => {
   if (debouncedDetails === procedure?.details) return
   
    void updateProcedureMutation.mutateAsync({
      vId: visitId,
      chiefProcedure: { ...procedure, details: debouncedDetails },
    })
  }, [debouncedDetails])

  React.useEffect(() => {
    const fetchClinicalProcedureFields = async (): Promise<void> => {
      const fieldsMap = new Map()
      for (const filterProcedure of filteredList) {
        const fields = await getClinicalProcedureFields(
          filterProcedure.procedure?.clinicalProcedureId ?? 0,
          getToken
        )
        fieldsMap.set(filterProcedure.procedure.clinicalProcedureId, fields)
      }
      setClinicalProcedureFields(fieldsMap)
    }

    fetchClinicalProcedureFields().catch((error) => {
      throw error
    })
  }, [filteredList, getToken])

  const handleProcedureTypeChange = (
    event: any,
    newValue: {
      id: number | undefined;
      label: string | null | undefined;
    } | null
  ): void => {
    if (newValue === null) {
      setProcedureTypeSelected({ id: -1, label: '' })
    } else {
      setProcedureTypeSelected({
        id: newValue.id ?? -1,
        label: newValue.label ?? '',
      })
    }
    setViewProcedure(newValue !== null)
  }

  const handleDetailsChange = (event: any): void => {
    setDetails(event.target.value)
  }

  React.useEffect(() => {
    setProcedureCPTCodes(procedure?.visitClinicalProcedureList ?? [])
  }, [procedure])

  React.useEffect(() => {
    setFilteredList(
      procedureCPTCodes
        .map((procedure: ProcedureCPTCode, index: number) => {
          return { procedure, index }
        })
        .filter((filterProcedure: FilterProcedure) => {
          return (
            filterProcedure.procedure?.clinicalProcedureId !== null &&
            filterProcedure.procedure?.clinicalProcedureId !== 193 &&
            !procedureTypes
              ?.filter(
                (pt) =>
                  pt.category?.toLocaleLowerCase() === 'e/m' ||
                  pt.category?.toLocaleLowerCase() === 'fee'
              )
              .map((pt) => {
                return pt.id
              })
              .includes(filterProcedure.procedure?.clinicalProcedureId)
          )
        })
        .sort(
          (a, b) =>
            (a.procedure.clinicalProcedureId ?? 1000) -
            (b.procedure.clinicalProcedureId ?? 0)
        )
    )
  }, [procedureCPTCodes, procedureTypes])

  React.useEffect(() => {
    if (procedureTypeSelected?.id <= 0) {
      setResults('')
      setCPTCode('')
      setViewProcedure(false)
      setStatus(1)
      setFields({})
      setFormData({})
      setSelectedModifiers([])
      setUiSchema({})
      setJsonSchema({})
      return
    }
    // Define the async function
    async function fetchData(): Promise<any> {
      try {
        const pt = procedureTypes?.find(
          (procedureType: ProcedureType) =>
            procedureType.id === procedureTypeSelected?.id
        ) ?? {
          id: 0,
          name: '',
          category: '',
          code: '',
        }
        // formRef.current.reset()
        const myFields = await getClinicalProcedureFields(
          procedureTypeSelected.id,
          getToken
        )
        const value = await generateSchemas(
          myFields,
          `${procedureTypeSelected.id}`,
          `${procedureTypeSelected.label}`
        )
        setFields(myFields)
        setJsonSchema(value.jsonSchema)
        setUiSchema(value.uiSchema)
        setCPTCode(pt.code ?? '')
      } catch (error) {
        console.error('An error occurred while fetching data:', error)
      }
    }

    fetchData().catch((error) => {
      throw error
    })
  }, [getToken, procedureTypeSelected.id, procedureTypeSelected.label, procedureTypes])

  const validateNDC = (formData: any): boolean => {
    let errors = false
    Object.keys(formData).forEach((key) => {
      if (jsonSchema.properties[key].title === 'NDC') {
        const pattern =
          /^(?:\d{4}-\d{4}-\d{2}|\d{5}-\d{3}-\d{2}|\d{5}-\d{4}-\d{1}|\d{5}-\d{4}-\d{2})$/
        if (!pattern.test(formData[key])) {
          errors = true
        }
      }
    })
    return errors
  }

  const widgets = {
    TextWidget: CustomTextbox,
    CheckboxWidget: CustomCheckbox,
    SelectWidget: CustomSelect,
    MultiselectWidget: CustomPillSearch,
    DatePickerWidget: CustomDate,
    RadioWidget: CustomRadio,
    DateTimePickerWidget: CustomDateTimePicker,
  }

  const mutateHasWritten = (hasWritten: boolean): void => {
    void updateProcedureMutation.mutateAsync({
      vId: visitId,
      chiefProcedure: { ...procedure, writtenConsent: hasWritten },
    })
  }
  const mutateHasVerbal = (hasVerbal: boolean): void => {
    void updateProcedureMutation.mutateAsync({
      vId: visitId,
      chiefProcedure: { ...procedure, verbalConsent: hasVerbal },
    })
  }

  const addProcedure = (): void => {
    if (procedureTypeSelected?.id === 0) return
    if (validateNDC(formRef.current.state.formData)) return
    const newProcedure: ProcedureCPTCode = {
      textResult: results,
      clinicalProcedureId: procedureTypeSelected?.id ?? 0,
      clinicalProcedureStatusId: status,
      cptCodeOther: cPTCode,
      visitClinicalProcedureFieldList: mapFormDataToDTO(
        formRef.current.state.formData,
        fields,
        procedureTypeSelected?.id
      ).filter((field: any) => field.clinicalProcedureFieldId !== null),
      visitClinicalProcedureCptModifierCodeList: selectedModifiers.map(
        (modifier) => {
          return {
            clinicalProcedureId: procedureTypeSelected?.id ?? 0,
            cptModifierCodeId: modifier,
          }
        }
      ),
    }
    if (isEdit) {
      console.log(procedureId)
      newProcedure.id = procedureId
      newProcedure.visitId = visitId
      void editProcedureMutation.mutateAsync({
        visitClinicalProcedureId: procedureId,
        chiefProcedure: newProcedure,
      })
      setIsEdit(false)
      setProcedureId(0)
    } else {
      const newCptCodes = [
        ...(procedure?.visitClinicalProcedureList ?? []),
        newProcedure,
      ]
      void updateProcedureMutation.mutateAsync({
        vId: visitId,
        chiefProcedure: { ...procedure, visitClinicalProcedureList: newCptCodes },
      })
    }
    setProcedureTypeSelected({ id: -1, label: '' })
    formRef.current.reset()
    ref?.current?.scrollIntoView({ behavior: 'smooth' })

    if (setTemplateUpdate !== undefined) {
      setTemplateUpdate(true)
    }
  }

  const handleProcedureDelete = (index: number): void => {
    console.log('handleProcedureDelete')
    setProcedureDeleted(true)
    const newCptCodes = procedure?.visitClinicalProcedureList?.filter(
      (value) => value.id !== index
    )
    void updateProcedureMutation.mutateAsync({
      vId: visitId,
      chiefProcedure: { ...procedure, visitClinicalProcedureList: newCptCodes },
    })
  }

  const handleEditProcedure = (
    procedure: ProcedureCPTCode,
    index: number
  ): void => {
    // Immediately Invoked Function Expression (IIFE)
    (async () => {
      console.log('handleEditProcedure')
      try {
        setIsEdit(true)
        setProcedureId(procedure.id ?? 0)
        setFilteredList(
          filteredList.filter((value) => value.procedure.id !== index)
        )
        const pT = procedureTypes?.find(
          (procedureType: ProcedureType) =>
            procedureType.id === procedure?.clinicalProcedureId
        ) ?? {
          id: 0,
          name: '',
          category: '',
          code: '',
        }
        setProcedureTypeSelected({
          id: procedure?.clinicalProcedureId ?? 0,
          label: `${pT.name ?? ''} - ${pT.category ?? ''} - ${pT.code ?? ''}`,
        })
        setResults(procedure?.textResult ?? '')
        setFormData(
          mapDTOToFormData(procedure?.visitClinicalProcedureFieldList ?? [])
        )
        setCPTCode(procedure?.cptCodeOther ?? '')
        // Since you are using `procedureTypeSelected` state just set above,
        // ensure you use the actual id from the procedure or pT for immediate use.
        const myFields = await getClinicalProcedureFields(
          procedure?.clinicalProcedureId ?? 0,
          getToken
        )
        setFields(myFields)
        const value = await generateSchemas(
          myFields,
          `${procedure?.clinicalProcedureId!}`,
          `${pT.name!} - ${pT.category!} - ${pT.code!}`
        )
        setJsonSchema(value.jsonSchema)
        setUiSchema(value.uiSchema)
        setSelectedModifiers(
          procedure?.visitClinicalProcedureCptModifierCodeList?.map(
            (mod) => mod.cptModifierCodeId ?? 0
          ) ?? []
        )
        setViewProcedure(true)
        setStatus(procedure?.clinicalProcedureStatusId ?? 1)
      } catch (error) {
        console.error('An error occurred in handleEditProcedure:', error)
      }
    })().catch((error: any) => {
      throw error
    })
  }

  React.useEffect(() => {
    if (inFocus) {
      if (setTemplateUpdate !== undefined) {
        setTemplateUpdate(false)
      }
    }
  }, [inFocus, setTemplateUpdate])

  const handleOnBlur = (): void => {
    setInFocus(false)
  }
  const buildProcedures = (): JSX.Element => {
    if (isUpdating) {
      return (
        <AddTable>
          <Shimmer width={'768px'} height={`${40 * filteredList.length}px`} />
        </AddTable>
      )
    }

    return (
      <AddTable>
        {filteredList.map((filterProcedure: FilterProcedure) => {
          const procedure = filterProcedure.procedure
          const modifier = procedure?.visitClinicalProcedureCptModifierCodeList
            ?.map((mods: any) => {
              return (
                cptModifiers.find(
                  (mod: any) => mod.id === mods.cptModifierCodeId
                )?.code ?? ''
              )
            })
            .join(', ')

          const myFields =
            clinicalProcedureFields.get(procedure.clinicalProcedureId) || []

          return (
            <ProcedureRow
              index={procedure.id}
              key={`${procedureTypes?.find(
                (procedureType) =>
                  procedureType.id === procedure?.clinicalProcedureId
              )
                ?.name!}_${Date.now()}_${procedure?.id!}_${procedure?.cptCodeOther!}_${Math.floor(Math.random() * 100001)}`}
              procedureType={
                procedureTypes?.find(
                  (procedureType) =>
                    procedureType.id === procedure?.clinicalProcedureId
                ) ?? {
                  name: '',
                  category: '',
                  code: '',
                }
              }
              procedure={procedure}
              procedureName={
                procedureTypes?.find(
                  (procedureType) =>
                    procedureType.id === procedure?.clinicalProcedureId
                )?.name ?? ''
              }
              fields={myFields}
              modifier={modifier ?? ''}
              delete={handleProcedureDelete}
              edit={handleEditProcedure}
            />
          )
        })}
      </AddTable>
    )
  }

  return (
    <TileLayout
      ref={ref}
      inFocus={inFocus}
      setInFocus={setInFocus}
      id="procedures"
      onBlur={handleOnBlur}
    >
      <TitleRow>
        <Title titleText="Orders & Procedures" />
        {isUpdating ? (
          <TileInputLabel>Saving...</TileInputLabel>
        ) : (
          <CheckBoxContainer sx={{ marginTop: '-18px' }}>
            <CheckBoxRow aria-disabled={isLocked}>
              <Checkbox
                checked={procedure?.writtenConsent ?? false}
                onChange={(event) => mutateHasWritten(event.target.checked)}
                disabled={isLocked}
              />
              <TileInputLabel>Written Consent</TileInputLabel>
            </CheckBoxRow>
            <CheckBoxRow>
              <Checkbox
                checked={procedure?.verbalConsent ?? false}
                onChange={(event) => mutateHasVerbal(event.target.checked)}
                disabled={isLocked}
              />
              <TileInputLabel>Verbal Consent</TileInputLabel>
            </CheckBoxRow>
          </CheckBoxContainer>
        )}
      </TitleRow>

      <TileRow>
        <div>
          <TileSearchBox
            id="search"
            dataTestId="oPType"
            label={'Order & Procedure Type'}
            options={procedureTypes
              ?.filter(
                (pt) =>
                  pt.category?.toLocaleLowerCase() !== 'e/m' &&
                  pt.category?.toLocaleLowerCase() !== 'fee'
              )
              .map((procedureType) => {
                return {
                  id: procedureType.id ?? 0,
                  label: `${procedureType.name ?? ''} - ${
                    procedureType.category ?? ''
                  } - ${procedureType.code ?? ''}`,
                }
              })}
            value={procedureTypeSelected}
            onChange={handleProcedureTypeChange}
            fullsize
          />
        </div>
      </TileRow>
      <TileRow>
        <Form
          className="rjsf"
          schema={jsonSchema}
          uiSchema={uiSchema}
          validator={validator}
          ref={formRef}
          templates={{ ButtonTemplates: { SubmitButton } }}
          formData={formData}
          widgets={widgets}
        />
      </TileRow>
      <Box hidden={!viewProcedure}>
        <TileRow>
          <TileTextField
            id="cptCode"
            label="CPT Code"
            value={cPTCode}
            inputProps={{ 'data-testid': 'cptCod' }}
            onChange={(event: any) => setCPTCode(event.target.value)}
          />
          <PillSelect
            label="Modifier"
            values={selectedModifiers}
            onChange={handleModifiersChange}
            options={cptModifiers.map((modifier) => {
              return { id: modifier.id ?? '', name: modifier.code ?? '' }
            })}
            setValues={setSelectedModifiers}
            dataTestId="procMod"
          />
        </TileRow>
        <TileRow>
          <TileTextArea
            value={results}
            onChange={(event: any) => setResults(event.target.value)}
            label="Note"
            fullsize
            dataTestId="procResults"
          />
        </TileRow>
        <ButtonRow>
          <AddButton
            title="Add Procedure"
            smaller
            onClick={addProcedure}
            dataTestId="addProcedure"
            disabled={isUpdating}
          />
        </ButtonRow>
      </Box>
      {
        /* prettier-ignore */
        (procedure?.visitClinicalProcedureList ?? []).length > 0
          /* prettier-ignore */
          ? (
            /* prettier-ignore */
            <TileRow>
              {/* prettier-ignore */}
              {buildProcedures()}
              {/* prettier-ignore */}
            </TileRow>
            /* prettier-ignore */
            )
          /* prettier-ignore */
          : <></>
        /* prettier-ignore */
      }

      <TileRow>
        <TileColumn aria-disabled={isLocked}>
          <div>
            {isUpdating ? (
              <Shimmer width="768px" height="260px" />
            ) : (
              <TileTextArea
                value={details}
                onChange={handleDetailsChange}
                label="Details"
                fullsize
                dataTestId="procDetails"
                disabled={isLocked}
              />
            )}
          </div>
        </TileColumn>
      </TileRow>
    </TileLayout>
  )
}

export interface ProceduresTileProps {
  visitId: number;
  procedureTypes: ProcedureType[];
  ref: React.RefObject<HTMLDivElement>;
  inFocus: boolean;
  setInFocus: React.Dispatch<React.SetStateAction<boolean>>;
  accountUsers: AccountUser[];
  isLocked?: boolean;
  cptModifiers: CPTModifier[];
  setIsDirty?: React.Dispatch<React.SetStateAction<boolean>>;
  setProcedureDeleted?: React.Dispatch<React.SetStateAction<boolean>>;
  procedureTypeSelected: { id: number; label: string };
  setProcedureTypeSelected: any;
  isUpdating?: boolean;
  templateUpdate?: boolean | undefined;
  setTemplateUpdate?: React.Dispatch<React.SetStateAction<boolean>>;
}

function SubmitButton(props: SubmitButtonProps): any {
  const { uiSchema } = props
  const { norender } = getSubmitButtonOptions(uiSchema)
  if (norender) {
    return null
  }
  return null
}
