import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  InputLabel,
  type SelectChangeEvent,
  styled,
  Tooltip,
} from '@mui/material'
import React from 'react'
import {
  TileSelect,
  TileSubtitle,
  Title,
  TileLayout,
  TileRow,
  TileInputLabel,
  AddTable,
  ProcedureRow,
} from '@/components'
import {
  type AssessmentDX,
  type ProcedureCPTCode,
  type DXModifiers,
  type CodingModifier,
  type CPTModifier,
  type ProcedureType,
  type Coding,
  type CodingSeverityEnum,
} from '@/types'
import { MDMRow } from './MDMRow'
import { TimeRow } from './TimeRow'
import { DXRow } from '../PlanAssessmentTile/DXRow'
import { AICodingTile } from './AICodingTile'
import { isEmpty } from '@/utility/utils'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import { useDXCodeStore } from '@/hook'
import { getAICoding, getClinicalProcedureFields } from '@/services'
import { useAuth } from '@clerk/nextjs'
import { AiEMCoding } from '@/types/AiEMCoding'

const CheckBoxRow = styled('div')({
  display: 'flex',
  alignItems: 'center',
})

const LabelRow = styled('div')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-evenly',
  marginLeft: '517px',
  paddingRight: '60px',
  width: '240px',
  marginTop: '-44px',
})

const VisitTypeContainer = styled('div')({
  minWidth: '175px',
  display: 'flex',
  flexDirection: 'column',
  gap: '5px',
})

const StyleInputLabel = styled(InputLabel)(({ theme }) => ({
  fontSize: '14px',
  lineHeight: '24px',
  color: theme.palette.primary.main,
  minWidth: '150px',
  marginLeft: '20px',
}))

const EMCodeContainer = styled('div')({
  marginTop: '-20px',
  justifyContent: 'space-between',
  display: 'flex',
  gap: '22px',
})

export const CodingTile = ({
  visitTypeId = 0,
  emCodes,
  procedures,
  diagnosis,
  dxModifiers,
  codingModifiers,
  ref,
  inFocus,
  setInFocus,
  isLocked = false,
  procedureTypes,
  coding,
  setCoding,
  cptModifiers,
  setIsDirty = () => {},
  emCode,
  setEmCode,
  aiEnabled,
  visitId,
}: CodingTileProps): JSX.Element => {
  const { getToken } = useAuth()
  const { dxCodes } = useDXCodeStore()
  const [isNewPatient, setIsNewPatient] = React.useState<boolean>(false)
  const [mdm, setMdm] = React.useState<boolean>(true)
  const [time, setTime] = React.useState<boolean>(false)
  const [numberComplexity, setNumberComplexity] = React.useState<number>(5)
  const [numberComplexityNotes, setNumberComplexityNotes] =
    React.useState<string>('')
  const [amountComplexity, setAmountComplexity] = React.useState<number>(5)
  const [amountComplexityNotes, setAmountComplexityNotes] =
    React.useState<string>('')
  const [risk, setRisk] = React.useState<number>(5)
  const [riskNotes, setRiskNotes] = React.useState<string>('')
  const [timeValue, setTimeValue] = React.useState<string>('')
  const [timeNotes, setTimeNotes] = React.useState<string>('')
  const [physical, setPhysical] = React.useState<boolean>(false)
  const [globalFee, setGlobalFee] = React.useState<boolean>(false)
  const [mDMValue, setMDMValue] = React.useState<number>(0)
  const [old, setOld] = React.useState<any[]>([])
  const [clinicalProcedureFields, setClinicalProcedureFields] = React.useState(
    new Map()
  )
  const [visitType, setVisitType] = React.useState<number>(0)
  const [warnAction, setWarnAction] = React.useState<{
    action: any;
    value: any;
  }>()
  const [disableChange, setDisableChange] = React.useState<boolean>(true)
  const [newPatientInit, setNewPatientInit] = React.useState<boolean>(true)
  const [suggestionInit, setSuggestionInit] = React.useState<boolean>(true)
  const [codingText, setCodingText] = React.useState<AiEMCoding>()

  React.useEffect(() => {
    setWarn(isLocked)
  }, [isLocked])
  React.useEffect(() => {
    setVisitType(
      coding?.visitTypeCoding
        ? coding.visitTypeCoding
        : coding?.isNewPatient === true ? 1 : 2
    )
  }, [coding?.visitTypeCoding, isNewPatient])
  React.useEffect(() => {
    setPhysical(coding?.visitTypeCoding === 3 || coding?.visitTypeCoding === 4)
  }, [coding?.visitTypeCoding])
  React.useEffect(() => {
    setEmCode(coding?.enmProcedureCptCodeId ?? 0)
  }, [coding?.enmProcedureCptCodeId])
  React.useEffect(() => {
    setGlobalFee(coding?.globalFee === true)
  }, [coding?.globalFee])
  React.useEffect(() => {
    setMdm(coding?.medicalDecisionMaking === true)
  }, [coding?.medicalDecisionMaking])
  React.useEffect(() => {
    setTime(coding?.enterTime === true)
  }, [coding?.enterTime])
  React.useEffect(() => {
    setNumberComplexity(coding?.encounterSeverityEnum ?? 0)
  }, [coding?.encounterSeverityEnum])
  React.useEffect(() => {
    setRisk(coding?.morbiditySeverityEnum ?? 0)
  }, [coding?.morbiditySeverityEnum])
  React.useEffect(() => {
    setAmountComplexity(coding?.dataReviewSeverityEnum ?? 0)
  }, [coding?.dataReviewSeverityEnum])
  React.useEffect(() => {
    setRiskNotes(coding?.morbiditySeverityNote ?? '')
  }, [coding?.morbiditySeverityNote])
  React.useEffect(() => {
    setNumberComplexityNotes(coding?.encounterSeverityNote ?? '')
  }, [coding?.encounterSeverityNote])
  React.useEffect(() => {
    setAmountComplexityNotes(coding?.dataReviewSeverityNote ?? '')
  }, [coding?.dataReviewSeverityNote])
  React.useEffect(() => {
    setTime(coding?.enterTime === true)
  }, [coding?.enterTime])
  React.useEffect(() => {
    setIsNewPatient(coding?.isNewPatient === true)
  }, [coding?.isNewPatient])
  React.useEffect(() => {
    const tempTimeSpent =
      coding?.timeSpent === null ||
      coding?.timeSpent === undefined ||
      coding?.timeSpent === 0
        ? ''
        : coding?.timeSpent.toString()
    setTimeValue(tempTimeSpent)
  }, [coding?.timeSpent])
  React.useEffect(() => {
    setTimeNotes(coding?.timeNote ?? '')
  }, [coding?.timeNote])
  React.useEffect(() => {
    if (visitType === 2 && (coding?.isNewPatient === true || isNewPatient)) {
      setVisitType(1)
    } else if (
      visitType === 1 &&
      (coding?.isNewPatient === false || !isNewPatient)
    ) {
      setVisitType(2)
    }

    if (visitType === 4 && (coding?.isNewPatient === true || isNewPatient)) {
      setVisitType(3)
    } else if (
      visitType === 3 &&
      (coding?.isNewPatient === false || !isNewPatient)
    ) {
      setVisitType(4)
    }
  }, [isNewPatient, coding?.isNewPatient])
  React.useEffect(() => {
    // Convert the MDM component values to an array
    const mdmComponents = [numberComplexity, risk, amountComplexity]

    // Filter out any components that haven't been selected (i.e., are 0)
    const selectedComponents = mdmComponents.filter((value) => value > 0)

    // Check if at least 2 components have been selected
    if (selectedComponents.length >= 2) {
      // Sort the selected components in ascending order so the highest value can be easily discarded
      const sortedComponents = selectedComponents.sort((a, b) => a - b)

      // Discard the highest value and take the next one
      // For 2 components, this will be the first (and lower) value
      // For 3 components, this will be the second value
      const suggestedMdmValue = sortedComponents[sortedComponents.length - 2] // Takes the second last item

      // Update the mDMValue state
      setMDMValue(suggestedMdmValue)
    }
  }, [numberComplexity, risk, amountComplexity])

  React.useEffect(() => {
    if (
      coding?.enmProcedureCptCodeId === undefined ||
      coding?.enmProcedureCptCodeId === null ||
      coding?.enmProcedureCptCodeId < 0
    ) {
      setNewPatientInit(false)
      setSuggestionInit(false)
      setDisableChange(true)
    }
    if (disableChange) {
      setDisableChange(false)
      return
    }

    if (newPatientInit) {
      setNewPatientInit(false)
      return
    }

    if (suggestionInit) {
      setSuggestionInit(false)
      return
    }

    setEmCode(0)
  }, [mDMValue, isNewPatient])
  const [open, setOpen] = React.useState(false)
  const [warn, setWarn] = React.useState(isLocked)
  const handleClickOpen = (): void => {
    setOpen(true)
  }
  const handleClose = (): void => {
    setOpen(false)
    setWarn(false) // Also sets the warn state to false
    if (warnAction) {
      warnAction.action(warnAction.value)
    }
  }

  const handleWarn = (action: any, value: any): void => {
    setDisableChange(false)
    if (warn) {
      setWarnAction({ action, value })
      handleClickOpen()
    } else {
      action(value)
    }
  }

  const onBlur = (): void => {
    const codingTimeNote = coding?.timeNote ?? ''
    const timeSpent = coding?.timeSpent ?? 0
    const timeValueParsed = isEmpty(timeValue) ? 0 : parseInt(timeValue)
    const moNote = isEmpty(coding?.morbiditySeverityNote)
      ? ''
      : coding?.morbiditySeverityNote
    const amNote = isEmpty(coding?.dataReviewSeverityNote)
      ? ''
      : coding?.dataReviewSeverityNote
    const nuNote = isEmpty(coding?.encounterSeverityNote)
      ? ''
      : coding?.encounterSeverityNote
    const parsedGlobalFee = coding?.globalFee ?? false
    const parseEnterTime = coding?.enterTime ?? false
    const parsedMdm = coding?.medicalDecisionMaking ?? true
    const parsedRisk = coding?.morbiditySeverityEnum ?? 5
    const parsedAmountComplexity = coding?.dataReviewSeverityEnum ?? 5
    const parsedNumberComplexity = coding?.encounterSeverityEnum ?? 5
    const parsedEmCode = coding?.enmProcedureCptCodeId ?? 0
    const dirty =
      isNewPatient !== coding?.isNewPatient ||
      globalFee !== parsedGlobalFee ||
      time !== parseEnterTime ||
      mdm !== parsedMdm ||
      risk !== parsedRisk ||
      riskNotes !== moNote ||
      amountComplexity !== parsedAmountComplexity ||
      amountComplexityNotes !== amNote ||
      numberComplexity !== parsedNumberComplexity ||
      numberComplexityNotes !== nuNote ||
      timeNotes !== codingTimeNote ||
      timeValueParsed !== timeSpent ||
      emCode !== parsedEmCode ||
      coding?.visitTypeCoding !== visitType
    setIsDirty(dirty)
    const newCoding: Coding = coding
    // prettier-ignore
    newCoding.visitTypeCoding = physical
      ? newCoding.isNewPatient === true
        ? 3
        : 4
      : newCoding.isNewPatient === true
        ? 1
        : 2
    newCoding.isNewPatient = isNewPatient
    newCoding.globalFee = globalFee
    newCoding.enmProcedureCptCodeId = emCode
    newCoding.visitTypeCoding = visitType as 2 | 1 | 3 | 4 | undefined
    newCoding.clinicalProcedureDTO?.forEach((procedure) => {
      procedure.visitClinicalProcedureCptModifierCodeList = null
    })
    newCoding.diagnosisCodeDtoList = null
    newCoding.medicalDecisionMaking = mdm
    if (mdm) {
      newCoding.morbiditySeverityEnum = risk as CodingSeverityEnum
      newCoding.morbiditySeverityNote = riskNotes
      newCoding.dataReviewSeverityEnum = amountComplexity as CodingSeverityEnum
      newCoding.dataReviewSeverityNote = amountComplexityNotes
      newCoding.encounterSeverityEnum = numberComplexity as CodingSeverityEnum
      newCoding.encounterSeverityNote = numberComplexityNotes
    }
    newCoding.enterTime = time
    if (time) {
      newCoding.enterTime = time
      newCoding.timeSpent =
        !isNaN(parseInt(timeValue)) && timeValue !== ''
          ? parseInt(timeValue)
          : 0
      newCoding.timeNote = timeNotes
    }
    if (
      newCoding.visitTypeCoding !== coding?.visitTypeCoding ||
      newCoding.clinicalProcedureDTO !== procedures ||
      newCoding.dataReviewSeverityEnum !== coding?.dataReviewSeverityEnum ||
      newCoding.dataReviewSeverityNote !== coding?.dataReviewSeverityNote ||
      newCoding.encounterSeverityEnum !== coding?.encounterSeverityEnum ||
      newCoding.enterTime !== coding?.enterTime ||
      newCoding.globalFee !== coding?.globalFee ||
      newCoding.isNewPatient !== coding?.isNewPatient ||
      newCoding.medicalDecisionMaking !== coding?.medicalDecisionMaking ||
      newCoding.morbiditySeverityEnum !== coding?.morbiditySeverityEnum ||
      newCoding.morbiditySeverityNote !== coding?.morbiditySeverityNote ||
      newCoding.timeNote !== coding?.timeNote ||
      newCoding.timeSpent !== coding?.timeSpent ||
      newCoding.enmProcedureCptCodeId !== coding?.enmProcedureCptCodeId
    ) {
      setCoding(newCoding)
    }
    setInFocus(false)
  }

  const handleMdmChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ): void => {
    if (event.target.checked) {
      setMdm(true)
    } else {
      setMdm(false)
    }
  }

  const handleTimeChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ): void => {
    if (event.target.checked) {
      setTime(true)
    } else {
      setTime(false)
    }
  }

  const handleEmCodeChange = (
    event: SelectChangeEvent<any>,
    child: React.ReactNode
  ): void => {
    if (event.target.value !== 0 && event.target.value !== -1) {
      setEmCode(event.target.value ?? 0)
    } else {
      setEmCode(0)
    }
  }

  const generateCoding = () => {
    if (visitId === undefined) {
      return
    }
    getAICoding(visitId, getToken)
      .then((response) => {
        setCodingText(response)
        setNumberComplexity(response.encounterSeverityEnum)
        setRisk(response.morbiditySeverityEnum)
        setAmountComplexity(response.dataReviewSeverityEnum)
      })
      .catch((error) => {
        console.error('error:', error)
      })
  }

  const handleGlobalFeeChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ): void => {
    if (event.target.checked) {
      setGlobalFee(true)
    } else {
      setGlobalFee(false)
    }
  }
  React.useEffect(() => {
    // Define an async function to fetch the data
    const fetchClinicalProcedureFields = async (): Promise<void> => {
      const fieldsMap = new Map()
      for (const filterProcedure of coding?.clinicalProcedureDTO ?? old) {
        const fields = await getClinicalProcedureFields(
          filterProcedure?.clinicalProcedureId ?? 0,
          getToken
        )
        fieldsMap.set(filterProcedure?.clinicalProcedureId, fields)
      }
      setClinicalProcedureFields(fieldsMap)
    }

    // Call the function
    fetchClinicalProcedureFields().catch((error) => {
      throw error
    })
  }, [coding?.clinicalProcedureDTO, getToken])

  const buildProcedures = React.useMemo((): JSX.Element => {
    if (
      coding?.clinicalProcedureDTO !== null &&
      coding?.clinicalProcedureDTO !== undefined
    ) {
      setOld(coding?.clinicalProcedureDTO)
    }
    return (
      <AddTable>
        {(coding?.clinicalProcedureDTO ?? old).map(
          (procedure: ProcedureCPTCode) => {
            const myFields =
              clinicalProcedureFields.get(procedure.clinicalProcedureId) || []
            const modifier =
              procedure?.visitClinicalProcedureCptModifierCodeList
                ?.map((mods: any) => {
                  return (
                    cptModifiers.find(
                      (mod: any) => mod.id === mods.cptModifierCodeId
                    )?.code ?? ''
                  )
                })
                .join(', ')
            const pt = procedureTypes.find(
              (procedureType) =>
                procedureType.id === procedure.clinicalProcedureId
            ) ?? {
              name: '',
              code: '',
              units: '',
              needsProcedureTextResult: false,
              needsOrderTextResult: false,
              hasImIv: false,
              hasOrderStatus: false,
              hasProcedureStatus: false,
              hasLeftRight: false,
            }
            return (
              <ProcedureRow
                key={`${procedureTypes?.find(
                  (procedureType) =>
                    procedureType.id === procedure?.clinicalProcedureId
                )?.name!}_${Date.now()}_${procedure?.id!}`}
                procedure={procedure}
                procedureName={
                  procedureTypes.find(
                    (procedureType) =>
                      procedureType.id === procedure.clinicalProcedureId
                  )?.name ?? ''
                }
                procedureType={pt}
                modifier={modifier ?? ''}
                fields={myFields}
              />
            )
          }
        )}
      </AddTable>
    )
  }, [
    procedureTypes,
    coding?.clinicalProcedureDTO,
    clinicalProcedureFields,
    cptModifiers,
    getToken,
  ])

  const buildAssessmentDXs = React.useMemo((): JSX.Element => {
    return (
      <AddTable>
        {diagnosis?.map((dx) => {
          const dxCode = dxCodes?.find(
            (code: any) => code.id === dx.diagnosisCodeId
          )
          return (
            <DXRow
              key={dx.diagnosisCodeId}
              dxCodeId={dx.diagnosisCodeId ?? 0}
              dxCode={`${dxCode?.name ?? ''} - ${dxCode?.icd10CmCode ?? ''}`}
              dxModifierId={dx.diagnosisModifierId ?? 0}
              dxModifier={
                dxModifiers.find((code) => code.id === dx.diagnosisModifierId)
                  ?.name ?? ''
              }
              codingModifierId={dx.diagnosisCodingModifierId ?? 0}
              codingModifier={
                codingModifiers.find(
                  (code) => code.id === dx.diagnosisCodingModifierId
                )?.name ?? ''
              }
            />
          )
        })}
      </AddTable>
    )
  }, [diagnosis, dxCodes, dxModifiers, codingModifiers])

  const mdMTooltip =
    'Medical decision making includes establishing diagnoses, assessing the status of a condition, and/or selecting a management option. Medical decision making is defined by three elements. To qualify for a particular level of medical decision making, two of the three elements for a level of medical decision making must be met or exceeded'
  const timeTooltip =
    'Total time will be measured as the time the physician or other qualified health professional spent on that patient on the day of the encounter. It will include time spent before, during, and after the visit, including time spent documenting the visit'
  const numberComplexityTooltip =
    'The number and complexity of the problems addressed during the encounter. The number and complexity of problems addressed during the encounter is defined by three elements. To qualify for a particular level of number and complexity of problems addressed, two of the three elements for a level of number and complexity of problems addressed must be met or exceeded'
  const numberComplexityText =
    'Number and Complexity of problem(s) addressed during the encounter'
  const amountComplexityTooltip =
    'The amount and/or complexity of data to be reviewed and analyzed during the encounter. The amount and complexity of data to be reviewed and analyzed during the encounter is defined by three elements. To qualify for a particular level of amount and complexity of data to be reviewed and analyzed, two of the three elements for a level of amount and complexity of data to be reviewed and analyzed must be met or exceeded'
  const amountComplexityText =
    'Amount and/or Complexity of data to be reviewed and analyzed'
  const riskTooltip =
    'The risk of complications and/or morbidity or mortality associated with the patient’s condition(s) or the risk of major complications associated with the management options being considered. The risk of morbidity or mortality associated with the patient’s condition(s) or the risk of major complications associated with the management options being considered is defined by three elements. To qualify for a particular level of risk of morbidity or mortality associated with the patient’s condition(s) or the risk of major complications associated with the management options being considered, two of the three elements for a level of risk of morbidity or mortality associated with the patient’s condition(s) or the risk of major complications associated with the management options being considered must be met or exceeded'
  const riskText =
    'Risk of complications and/or morbidity or mortality of patient management'
  const timeText = 'Total time spent on visit (in minutes)'
  return (
    <TileLayout
      ref={ref}
      id="coding"
      inFocus={inFocus}
      setInFocus={setInFocus}
      isLocked={false}
      onBlur={onBlur}
    >
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Warning</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            This chart has already been sent to billing, are you sure you want
            to add EM code?”
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)}>No</Button>
          <Button onClick={handleClose}>Yes</Button>
        </DialogActions>
      </Dialog>
      <Title titleText="Coding" />
      <TileRow>
        {
          // prettier-ignore
          visitTypeId !== 0
            ? (
              <VisitTypeContainer>
                <TileSelect
                  label="Visit Type"
                  options={[
                    { name: 'Office Visit', id: isNewPatient ? 1 : 2 },
                    { name: 'Physical', id: isNewPatient ? 3 : 4 },
                  ]}
                  dataTestId='visitType'
                  value={visitType}
                  onChange={(event: SelectChangeEvent<any>, child: React.ReactNode) => {
                    handleWarn(setVisitType, event.target.value)
                  }}
                />

              </VisitTypeContainer>)
            : <></>
        }
        {visitTypeId !== 0 ? (
          <div style={{ minWidth: '150px', paddingTop: '20px' }}>
            <CheckBoxRow>
              <Checkbox
                checked={isNewPatient}
                onChange={() => handleWarn(setIsNewPatient, !isNewPatient)}
                data-testid={'isNewPatient-checkbox'}
              />
              <TileSubtitle>New Patient</TileSubtitle>
            </CheckBoxRow>
          </div>
        ) : (
          <></>
        )}
      </TileRow>
      { aiEnabled ? 
        <>
          <TileRow wide>
            <TileSubtitle>Medical Decision Making</TileSubtitle>
            <Button
              onClick={generateCoding}
            >
              Generate Coding
            </Button>
          </TileRow>
          <AICodingTile
            visitId={visitId ?? 0}
            codingText={codingText}
          />
        </>
      
      : <>
      <TileSubtitle>Select either Medical Decision Making or Time</TileSubtitle>
      <CheckBoxRow>
        <Checkbox
          checked={mdm}
          onChange={(e) => handleWarn(handleMdmChange, e)}
          data-testid={'mdm-checkbox'}
        />
        <TileSubtitle>{'Medical Decision Making'}</TileSubtitle>
        <Tooltip title={mdMTooltip} placement="right">
          <InfoOutlinedIcon sx={{ fontSize: '12px', marginLeft: '2px' }} />
        </Tooltip>
      </CheckBoxRow>
      {
        /* prettier-ignore */
        mdm
          /* prettier-ignore */
          ? (
            <>
              <LabelRow>
                <TileInputLabel>Min</TileInputLabel>
                <TileInputLabel>Low</TileInputLabel>
                <TileInputLabel>Mod</TileInputLabel>
                <TileInputLabel>High</TileInputLabel>
              </LabelRow>
              <MDMRow
                value={numberComplexity}
                setValue={setNumberComplexity}
                note={numberComplexityNotes}
                setNote={setNumberComplexityNotes}
                tooltipText={numberComplexityTooltip}
                text={numberComplexityText}
                required={mdm}
                handleWarn={handleWarn}
              />
              <MDMRow
                value={amountComplexity}
                setValue={setAmountComplexity}
                note={amountComplexityNotes}
                setNote={setAmountComplexityNotes}
                tooltipText={amountComplexityTooltip}
                text={amountComplexityText}
                required={mdm}
                handleWarn={handleWarn}
              />
              <MDMRow
                value={risk}
                setValue={setRisk}
                note={riskNotes}
                setNote={setRiskNotes}
                tooltipText={riskTooltip}
                text={riskText}
                required={mdm}
                handleWarn={handleWarn}
              />
            </>
            /* prettier-ignore */
            )
            /* prettier-ignore */
          : (<></>)
      }
      <CheckBoxRow>
        <Checkbox
          checked={time}
          onChange={(e) => handleWarn(handleTimeChange, e)}
          data-testid={'time-checkbox'}
        />
        <TileSubtitle>Time</TileSubtitle>
        <Tooltip title={timeTooltip} placement="right">
          <InfoOutlinedIcon sx={{ fontSize: '12px', marginLeft: '2px' }} />
        </Tooltip>
      </CheckBoxRow>
      {
        /* prettier-ignore */
        time
          /* prettier-ignore */
          ? (
            <TimeRow
              value={timeValue}
              setValue={setTimeValue}
              note={timeNotes}
              setNote={setTimeNotes}
              text={timeText}
              handleWarn={(action: any, value: any) => handleWarn(action, value)}
            />
            /* prettier-ignore */
            )
          /* prettier-ignore */
          : (<></>)
      }
      </>
      }
      <TileRow>
        {visitTypeId !== 0 ? <TileInputLabel>E\M Code</TileInputLabel> : <></>}
        <StyleInputLabel>
          {mDMValue > 0 && mDMValue < 5
            ? `Suggested E\\M ${
                emCodes.find(
                  (code) =>
                    code?.type === (isNewPatient ? 1 : 2) &&
                    code?.risk === mDMValue
                )?.name ?? ''
              }`
            : ''}
        </StyleInputLabel>
      </TileRow>
      <EMCodeContainer>
        {
          // prettier-ignore
          visitTypeId !== 0
            ? (
              <TileRow>
                <TileSelect
                  // prettier-ignore
                  options={
                    physical
                      ? emCodes
                        .filter(
                          (code) =>
                            code?.type === (isNewPatient ? 3 : 4)
                        )
                      : emCodes
                        .filter(
                          (code) =>
                            code?.type === (isNewPatient ? 1 : 2)
                        )
                  }
                  dataTestId='emCode'
                  value={emCode}
                  onChange={(event) => handleWarn(handleEmCodeChange, event)}
                />
              </TileRow>)
            : <></>
        }
        <div style={{ minWidth: '150px', marginTop: '-6px' }}>
          <CheckBoxRow>
            <Checkbox
              checked={globalFee}
              onChange={(e) => handleWarn(handleGlobalFeeChange, e)}
              data-testid={'globalFee-checkbox'}
            />
            <TileSubtitle>Global Fee</TileSubtitle>
          </CheckBoxRow>
        </div>
      </EMCodeContainer>
      <Title titleText={'Procedure Codes'} />
      {buildProcedures}
      <Title titleText={'Diagnosis Codes'} />
      {buildAssessmentDXs}
    </TileLayout>
  )
}

interface CodingTileProps {
  /* prettier-ignore */
  visitTypeId?: number
  /* prettier-ignore */
  emCodes: Array<{ name: string, id: number, type: number, risk: number }>
  procedures: ProcedureCPTCode[];
  diagnosis: AssessmentDX[];
  dxModifiers: DXModifiers[];
  codingModifiers: CodingModifier[];
  ref: React.RefObject<HTMLDivElement>;
  inFocus: boolean;
  setInFocus: React.Dispatch<React.SetStateAction<boolean>>;
  isLocked?: boolean;
  procedureTypes: ProcedureType[];
  coding: Coding;
  setCoding: any;
  cptModifiers: CPTModifier[];
  setIsDirty?: React.Dispatch<React.SetStateAction<boolean>>;
  emCode: number;
  setEmCode: React.Dispatch<React.SetStateAction<number>>;
  aiEnabled?: boolean | null
  visitId?: number
}
