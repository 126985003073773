import {
  Typography,
  Button,
  styled,
  Popover,
  CircularProgress,
} from '@mui/material'
import { Stack, Box } from '@mui/system'
import Link from 'next/link'
import React from 'react'
import {
  FileIcon,
  defaultStyles,
  type DefaultExtensionType,
} from 'react-file-icon'
import { TaskDrawerSection } from './TaskDrawerSection'
import { DocumentsPopover } from '../../Task/DocumentsPopover'
import PopupState, { bindPopover, bindTrigger } from 'material-ui-popup-state'
import UploadIcon from '@mui/icons-material/Upload'
import { DocumentUploadDialog } from '../../Task/DocumentUploadDialog'
import { useTasksStore } from '@/hook/useTasks'
import { useAuth } from '@clerk/nextjs'
import { type Task, type UrgentIQDocument } from '@/types'
import { useDocument } from '@/hook/useDocument'

const DocumentName = styled(Typography)({
  fontSize: '14px',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  width: '150px',
  whiteSpace: 'nowrap',
})

const CountCircle = styled('div')(({ theme }): any => ({
  width: '24px',
  height: '24px',
  borderRadius: '50%',
  background: '#EAF3FF',
  fontWeight: 700,
  fontSize: '10x',
  lineHeight: '12px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  marginTop: '-4px',
}))

export const AttachmentDrawerSection = ({
  taskId,
}: AttachmentDrawerSectionProps): JSX.Element => {
  const { getToken } = useAuth()
  const { taskPage } = useTasksStore()
  const [task, setTask] = React.useState<Task>()
  const [isFetching, setIsFetching] = React.useState<boolean>(false)
  const [taskDocuments, setTaskDocuments] = React.useState<UrgentIQDocument[]>(
    []
  )
  const { getDocument, documents, fetchingIds } = useDocument()

  React.useEffect(() => {
    const task = taskPage.items?.find((task) => task.id === taskId)
    if (!task) {
      return
    }
    setTask(task)

    if (!task.documentId || task.documentId.length === 0) {
      return
    }
    const taskDocs: UrgentIQDocument[] = task.documentId.reduce(
      (acc: UrgentIQDocument[], docId) => {
        const doc = getDocument(docId, getToken)
        if (doc !== null) {
          acc.push(doc)
        }
        return acc
      },
      []
    )

    setTaskDocuments(taskDocs)
  }, [taskId, taskPage, documents])

  React.useEffect(() => {
    if (fetchingIds.size > 0) {
      setIsFetching(true)
    } else {
      setIsFetching(false)
    }
  }, [fetchingIds])

  const ext = React.useMemo(
    () => taskDocuments[0]?.visibleDocumentName?.split('.').reverse()[0] ?? '',
    [taskDocuments]
  )

  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null)
  const [uploadOpen, setUploadOpen] = React.useState<boolean>(false)

  const handlePopoverToggle = (event: React.MouseEvent<HTMLElement>): void => {
    setAnchorEl((prev) => (prev ? null : event.currentTarget))
  }

  const handleUploadSuccess = (): void => {
    setUploadOpen(false)
  }

  const renderPopupState: React.FC<any> = (popupState: any) => (
    <>
      <Box onClick={handlePopoverToggle}>
        <CountCircle id="documents-modal" {...bindTrigger(popupState)}>
          +{taskDocuments.length - 1}
        </CountCircle>
      </Box>
      <Popover
        {...bindPopover(popupState)}
        anchorOrigin={{
          horizontal: 'left',
          vertical: 'top',
        }}
        anchorEl={anchorEl}
        transformOrigin={{
          vertical: 'center',
          horizontal: 'right',
        }}
        onClose={(ev: any): void => {
          popupState.close()
          setAnchorEl(null)
          ev.stopPropagation()
        }}
      >
        <DocumentsPopover documentIds={task?.documentId ?? []} />
      </Popover>
    </>
  )

  return (
    <TaskDrawerSection sectionName="Attachment">
      <Stack direction="row" spacing={4} alignItems="center">
        {taskDocuments?.length ? (
          <>
            <Link href={taskDocuments[0]?.presignedUrl ?? '#'} target="_blank">
              <Stack direction="row">
                <Box
                  sx={{ display: 'flex', width: '20px', marginRight: '15px' }}
                >
                  <FileIcon
                    extension={ext}
                    {...defaultStyles[ext as DefaultExtensionType]}
                    labelUppercase
                  />
                </Box>
                <DocumentName>
                  {taskDocuments[0]?.visibleDocumentName}
                </DocumentName>
              </Stack>
            </Link>
            {taskDocuments?.length > 1 && (
              <PopupState variant="popover" popupId="documents-modal-popover">
                {renderPopupState}
              </PopupState>
            )}
          </>
        ) : (
          <Typography sx={{ fontSize: '14px' }}>No attachments</Typography>
        )}
        {isFetching && <CircularProgress size={15} />}
        <Button variant="contained" onClick={() => setUploadOpen(true)}>
          <UploadIcon
            style={{
              cursor: 'pointer',
              width: '17px',
              height: '17px',
            }}
          />
        </Button>
      </Stack>
      <DocumentUploadDialog
        UserTaskId={taskId}
        open={uploadOpen}
        onClose={() => handleUploadSuccess()}
      />
    </TaskDrawerSection>
  )
}

interface AttachmentDrawerSectionProps {
  taskId?: number;
}
