import React from 'react'
import { useAuth } from '@clerk/nextjs'
import { WorkCompOccMedTile } from '@/components/PatientIntake'
import { type SubmitHandler, useForm } from 'react-hook-form'
import * as Yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { dateOnlyToDate, isEmpty } from '@/utility'
import { createOCCMedObject, createWorkerCompObject } from '@/helpers'
import {
  useClinicStore,
  useMutateAddOccMed,
  useMutateAddWorkersComp,
  useQueryGetStates,
  useQueryGetVisitTypes,
} from '@/hook'

export const WorkersCompModal = ({
  visitId,
  data,
  visitType,
  refresh,
  handleClose,
}: WorkersCompModalProps): JSX.Element => {
  const { getToken } = useAuth()
  const { clinicId } = useClinicStore()
  const addWorkersCompMutation = useMutateAddWorkersComp(getToken)
  const addOccMedMutation = useMutateAddOccMed(getToken)
  const { data: states } = useQueryGetStates(getToken)
  const { data: visitTypes } = useQueryGetVisitTypes(clinicId, getToken)

  const isWorkersComp = React.useMemo(() => {
    return (
      visitTypes
        ?.find((type) => type.id === visitType)
        ?.name?.includes('Work') === true
    )
  }, [visitTypes, visitType])

  const isOccMed = React.useMemo(() => {
    return visitType === 4
  }, [visitType])

  const validationSchema = Yup.object().shape({
    ...(isWorkersComp && {
      workersCompAccidentDate: Yup.date()
        .required('Accident Date is required')
        .nullable()
        .typeError('Accident Date is required'),
      workersCompAccidentStateId: Yup.number().required(
        'Accident State is required'
      ),
      workersCompEmployerName: Yup.string().required(
        'Employer Name is required'
      ),
      workersCompEmployerPhoneNumber: Yup.lazy((value) =>
        !isEmpty(value)
          ? Yup.string().nullable().min(12, 'Valid phone number is required')
          : Yup.string().nullable().required('Phone number is required')
      ),
    }),
    ...(isOccMed && {
      occMedEmployer: Yup.string().required('Employer Name is required'),
    }),
  })

  const {
    handleSubmit,
    register,
    control,
    setError,
    setValue,
    getValues,
    formState: { errors },
  } = useForm<any>({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      workersCompClaimNumber: data?.workersCompClaimNumber,
      workersCompAccidentDate: dateOnlyToDate(data.workersCompAccidentDate),
      workersCompAccidentStateId: data?.workersCompAccidentStateId,
      workersCompEmployerName: data?.workersCompEmployerName,
      //
      workersCompEmployerPhoneNumber: data?.workersCompEmployerPhone,
      //
      workersCompEmployerContactName: data?.workersCompEmployerContactName,
      workersCompEmployerEmail: data?.workersCompEmployerEmail,
      ///
      workersCompEmployerAddressstreet1:
        data?.workersCompEmployerAddressStreet1,
        //
        workersCompEmployerAddressstreet2:
        data?.workersCompEmployerAddressStreet2,
        //
        workersCompEmployerAddresscity: data?.workersCompEmployerAddressCity,
      //
      workersCompEmployerAddressstate:
      states?.find(
        (state) => state.id === data.workersCompEmployerAddressStateId)?.code,
        // data?.workersCompEmployerAddressStateId,
        //
        workersCompEmployerAddresszip:
        data?.workersCompEmployerAddressZipcode,
      occMedEmployer: data?.occMedEmployer,
      occMedEmployerPhone: data?.occMedEmployerPhone,
      occMedEmployerEmail: data?.occMedEmployerEmail,
      occMedContactName: data?.occMedContactName,
      occMedAddressLine: data?.occMedAddressLine,
      occMedAddressLine2: data?.occMedAddressLine2,
      occMedCity: data?.occMedCity,
      occMedStateId: data?.occMedStateId,
      occMedZip: data?.occMedZip,
    },
  })

  const onSubmit: SubmitHandler<any> = async (data): Promise<void> => {
    const workCompOccMedTask = isWorkersComp
      ? addWorkersCompMutation.mutateAsync({
          visitId,
          workersComp: createWorkerCompObject(visitId, data, states ?? []),
        })
      : isOccMed
      ? addOccMedMutation.mutateAsync({
          visitId,
          occMed: createOCCMedObject(visitId, data, states ?? []),
        })
      : Promise.resolve()

    await workCompOccMedTask
    refresh().catch((error: any) => {
      throw error
    })
    handleClose()
  }

  return (
    <form
      onSubmit={(...args): void => {
        handleSubmit(onSubmit)(...args).catch((error) => {
          throw error
        })
      }}
    >
      <WorkCompOccMedTile
        onFocus={() => {}}
        innerRef={null}
        register={register}
        control={control}
        values={data}
        setValue={setValue}
        getValues={getValues}
        errors={errors}
        setError={setError}
        isWorkersComp={isWorkersComp}
        handleClose={handleClose}
        isModal={true}
        claimInputValueModal={data?.workersCompClaimNumber}
      />
    </form>
  )
}

interface WorkersCompModalProps {
  visitId: number;
  data: any;
  visitType: number;
  handleClose: () => void;
  refresh: any;
}
