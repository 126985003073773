//WorkCompOccMedTile.tsx
import React from 'react'
import { getWorkersCompByPatientID } from '@/services/WorkersCompByPatientService'
import { useEffect, useState } from 'react'
import {
  Divider,
  InputLabel,
  styled,
  TextField,
  Select,
  MenuItem,
  //dropdown functionality with search capabilities
  Autocomplete,
} from '@mui/material'
import {
  type UseFormRegister,
  type FieldValues,
  type Control,
  Controller,
} from 'react-hook-form'
import {
  TileTitleBox,
  AddressInput,
  SectionLabel,
  PhoneNumberInput,
  ErrorMessage,
  RedText,
  ModalButton,
  ModalButtonRow,
} from '@/components'
import { useAuth } from '@clerk/nextjs'
import { TitleRow } from '@/components/Charting/Editor/VitalsTile/styles'
import { isEmpty, parseDateToDateOnly } from '@/utility/utils'
import {
  useClinicStore,
  useQueryGetStates,
  useQueryGetVisitTypes,
} from '@/hook'
import { Box } from '@mui/system'
import { DatePicker } from '@/components/DatePicker'
import { format } from 'date-fns'

const DemographicsContainer = styled('div')(({ theme }): any => ({
  display: 'flex',
  width: '812px',
  padding: '24px',
  borderRadius: '12px',
  backgroundColor: theme.palette.background.default,
  flexDirection: 'column',
  justifyContent: 'space-evenly',
  border: '1px solid #CDD0E3',
  scrollMarginTop: '100px',
  boxShadow:
    '0px 8px 4px rgba(221, 223, 232, 0.25), 0px 4px 15px rgba(64, 71, 118, 0.1)',
}))
const DemographicsRow = styled('div')({
  display: 'flex',
})
const DemographicsInnerRow = styled(DemographicsRow)({
  gap: '24px',
})
const AutoCompleteColumn = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  width: '100%',
  alignContent: 'flex-start',
  paddingRight: '10px',
  paddingBottom: '8px',
  gap: '24px',
})

interface WorkersCompOccMedProps {
  innerRef: React.RefObject<HTMLDivElement> | null
  register: UseFormRegister<FieldValues>
  control: Control<FieldValues, any>
  onFocus: any
  setValue: any
  errors: any
  values?: any
  setError: any
  getValues: any
  handleClose?: any
  isWorkersComp?: boolean
  isModal?: boolean
  patientId?: number
  claimInputValueModal?: string
}

export const WorkCompOccMedTile = ({
  innerRef,
  register,
  control,
  onFocus,
  setValue,
  errors,
  values,
  setError,
  handleClose,
  isModal = false,
  isWorkersComp = true,
  patientId, 
  claimInputValueModal = ''
}: WorkersCompOccMedProps): JSX.Element => {
  const { getToken } = useAuth()
  const { clinicId } = useClinicStore()
  const { data: states } = useQueryGetStates(getToken)
  const { data: visitTypes } = useQueryGetVisitTypes(clinicId, getToken)

  // State to store processed previous Workers Comp cases
  const [previousWorkCompCases, setPreviousWorkCompCases] = useState<any[]>([])
  const [claimInputValue, setClaimInputValue] = useState<string>(claimInputValueModal)

  const occMedTitle = React.useMemo(() => {
    return visitTypes?.find((type) => type?.id === 4)?.name ?? ''
  }, [visitTypes])

 // Utility function to parse backend accident date
const parseAccidentDate = (workersCompAccidentDate: any) => {
  const { year, month, day } = workersCompAccidentDate
  const formattedDate = new Date(year, month - 1, day) // JavaScript Date uses zero-based months
  return format(formattedDate, 'MM/dd/yyyy') // Convert to MM/dd/yyyy format
}

// Declaring function to process BE field keys to match the expected format
const processFieldKeys = (data: any, states: any) => {
  const processedData: Record<string, any> = {} // Create an object to store processed keys

  // Loop through each key in the backend data
  Object.keys(data).forEach((key: string) => {

    // Handle phone number (renamed to PhoneNumber)
    if (key.includes('Phone')) {
      processedData[`${key}Number`] = data[key] ?? ''
      
    // Handle address fields
    } else if (key.includes('Address')) {
      // Process Street 1 and Street 2 separately
      if (key.includes('Street1')) {
        processedData[`${key.replace('Street1', 'AddressStreet1')}`] = data[key] ?? ''
      } else if (key.includes('Street2')) {
        processedData[`${key.replace('Street2', 'AddressStreet2')}`] = data[key] ?? ''
      }
      
      // Handle Zipcode -> AddressZipcode
      if (key.includes('Zipcode')) {
        processedData[`${key.replace('Zipcode', 'AddressZipcode')}`] = data[key] ?? ''
      }

      // Handle City -> AddressCity
      if (key.includes('City')) {
        processedData[`${key.replace('City', 'AddressCity')}`] = data[key] ?? ''
      }

      // Handle StateId (map to state code, then find state ID)
      if (key.includes('StateId')) {
        processedData[`${key.replace('StateId', 'AddressStateId')}`] = states?.find(
          (state: any) => state.id === data[key]
        )?.code ?? ''
      }
      
    // Handle accident date transformation
    } else if (key.includes('AccidentDate')) {
      // Parse accident date from backend structure
      processedData[key] = parseAccidentDate(data[key]) ?? ''

    // Handle other fields by directly mapping
    } else {
      processedData[key] = data[key] ?? ''
    }
  })

  return processedData // Return the modified object
}

// Fetch WorkersComp claims on component mount, process the claims, and set them to state
useEffect(() => {
  const fetchWorkersCompClaims = async () => {
    if (!patientId) {
      return // Ensure patientId is valid
    }

    try {
      // Use the getWorkersCompByPatientID function (service) to fetch the WorkersComp claims
      const fetchedClaims = await getWorkersCompByPatientID(patientId, getToken)

      // Create a Set to track unique claim numbers
      const uniqueClaimNumbers = new Set()

      // Process the fetched claims and skip duplicates
      const processedClaims = fetchedClaims.reduce((acc: any[], claim: any) => {
        const claimNumber = claim.workersCompClaimNumber

        // Check if the claim number is already in the Set
        if (!uniqueClaimNumbers.has(claimNumber)) {
          uniqueClaimNumbers.add(claimNumber) // Add the new claim number to the Set
          acc.push(processFieldKeys(claim, states)) // Process and add the claim
        }

        return acc
      }, [])

      setPreviousWorkCompCases(processedClaims) // Set the processed claims to state
    } catch (error) {
      setError('Failed to fetch WorkersComp claims.') // Set error state
    }
  }

  // Call the fetchWorkersCompClaims function
  fetchWorkersCompClaims()
}, [patientId, getToken])


  
    // Update field values when a previous Workers Comp claim is selected
    useEffect(() => {
      const selectedClaim = previousWorkCompCases.find(
        (claim) => claim.workersCompClaimNumber === claimInputValue
      )
      if (selectedClaim) {
        // Set the fields that are working
        Object.keys(selectedClaim).forEach((key) => {
          setValue(key, selectedClaim[key])

        })
    
        // Additional fields to set, ValueNames from handleAddressSelect in AddressInput component & values from BE data fields
        if (selectedClaim.AccidentDate) {
          setValue('AccidentDate', parseDateToDateOnly(new Date(selectedClaim.AccidentDate)))
        }
        if (selectedClaim.AccidentStateId) {
          const accidentStateCode = states?.find((state) => state.id === selectedClaim.AccidentStateId)?.code ?? ''
          setValue('AccidentState', accidentStateCode)
        }
        if (selectedClaim.workersCompEmployerAddressAddressStreet1) {
          setValue('workersCompEmployerAddressstreet1', selectedClaim.workersCompEmployerAddressAddressStreet1)
          
        }
        if (selectedClaim.workersCompEmployerAddressAddressStreet2) {
          setValue('workersCompEmployerAddressstreet2', selectedClaim.workersCompEmployerAddressAddressStreet2)
        }
        if (selectedClaim.workersCompEmployerAddressAddressCity) {
          setValue('workersCompEmployerAddresscity', selectedClaim.workersCompEmployerAddressAddressCity)
        }
        if (selectedClaim.workersCompEmployerAddressAddressStateId) {
          setValue('workersCompEmployerAddressstate',selectedClaim.workersCompEmployerAddressAddressStateId)
        }
        if (selectedClaim.workersCompEmployerAddressAddressZipcode) {
          setValue('workersCompEmployerAddresszip', selectedClaim.workersCompEmployerAddressAddressZipcode)
        }
      }
    }, [claimInputValue, previousWorkCompCases, setValue, states])
    

  return (
    <DemographicsContainer ref={innerRef} onMouseOver={onFocus}>
      <TitleRow>
        <TileTitleBox
          tileTitle={isWorkersComp ? "Worker's Comp" : occMedTitle}
        />
      </TitleRow>
      {/*Start of the Workers' Compensation specific fields*/}
      {isWorkersComp && (
        <>
          <DemographicsRow> {/* Outer container for arranging input fields within a flexbox row */}
            <AutoCompleteColumn> {/* Column container for holding the Autocomplete component and related fields */}
              <DemographicsInnerRow> {/* Row within the column for grouping related inputs */}
                <div> {/* Container for the accident date picker input */}
                  <DatePicker
                    name={'workersCompAccidentDate'}
                    control={control}
                    label="Accident Date"
                    errors={errors}
                    maxDate={new Date(Date.now())}
                    dataTestId={'workersCompAccidentDate'}
                    required
                    defaultValue={null}
                  />
                </div>
                <div> {/* Container for accident state input */}
                  <InputLabel id="StateLabel">
                    Accident State<RedText>{' *'}</RedText>
                  </InputLabel>

                  <Controller
                    name={'workersCompAccidentStateId'}
                    control={control}
                    defaultValue={values?.workersCompAccidentStateId}
                    render={({ field }) => (
                      <Select
                        sx={{ width: '240px', background: 'white' }}
                        {...field}
                        labelId="AccidentStateLabel"
                        tabIndex={11}
                        data-testid={'workersCompAccidentStateId'}
                        value={field.value ?? ''}
                      >
                        {states?.map((state) => {
                          return (
                            <MenuItem
                              key={state.id}
                              value={state.id}
                              data-testid={`state-${state.code!}`}
                            >
                              {state.name}
                            </MenuItem>
                          )
                        })}
                      </Select>
                    )}
                  />
                  {!isEmpty(errors?.workersCompAccidentStateId) && (
                    <ErrorMessage
                      error={
                        (errors?.workersCompAccidentStateId
                          .message as string) ?? ''
                      }
                    />
                  )}
                </div>
                <div>
                <InputLabel>Claim #</InputLabel>
                <Controller
                  name="workersCompClaimNumber" // Registering the input field with react-hook-form
                  control={control} // Control object from react-hook-form
                  defaultValue={claimInputValue} // Default value managed by the component state
                  render={({ field }) => ( // Render the Autocomplete component
                    <Autocomplete
                      {...field} // Spread Controller field properties
                      options={previousWorkCompCases.map((claim) => claim.workersCompClaimNumber)} // Options for the dropdown
                      freeSolo // Allow input of any value
                      value={claimInputValue} // Controlled value for the input
                      onInputChange={(_, newValue) => {
                        setClaimInputValue(newValue)
                        field.onChange(newValue)
                      }}
                      onChange={(_, newValue) => {
                        setClaimInputValue(newValue ?? '')
                        field.onChange(newValue)
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params} // Spread params for styling and functionality
                          sx={{ width: '240px' }} // Width styling
                          inputProps={{
                            ...params.inputProps, 
                            'data-testid': 'workersCompClaimNumber', // For testing
                            }}
                      />
                    )}
    />
  )}
/>
</div>

              </DemographicsInnerRow>
            </AutoCompleteColumn>
          </DemographicsRow>
          <Divider
            sx={{ marginTop: '36px', marginBottom: '36px', color: '#DDDFE8' }}
            flexItem
          />
          <SectionLabel sectionLabelText="Patient Employer" />
        </>
      )}
      <DemographicsInnerRow sx={{ mb: 2 }}>
        <div>
          <InputLabel>
            Employer<RedText>{' *'}</RedText>
          </InputLabel>
          <TextField
            {...register(
              `${isWorkersComp ? 'workersCompEmployerName' : 'occMedEmployer'}`
            )}
            tabIndex={2}
            sx={{ width: '240px' }}
            inputProps={{
              'data-testid': `${
                isWorkersComp ? 'workersCompEmployerName' : 'occMedEmployer'
              }`,
            }}
          />
          {!isEmpty(errors?.workersCompEmployerName) && (
            <ErrorMessage
              error={errors?.workersCompEmployerName.message ?? ''}
            />
          )}
          {!isEmpty(errors?.occMedEmployer) && (
            <ErrorMessage error={errors?.occMedEmployer.message ?? ''} />
          )}
        </div>
        <div>
          <InputLabel>Contact Name</InputLabel>
          <TextField
            {...register(
              `${
                isWorkersComp
                  ? 'workersCompEmployerContactName'
                  : 'occMedContactName'
              }`
            )}
            tabIndex={2}
            sx={{ width: '240px' }}
            inputProps={{
              'data-testid': `${
                isWorkersComp
                  ? 'workersCompEmployerContactName'
                  : 'occMedContactName'
              }`,
            }}
          />
        </div>
      </DemographicsInnerRow>
      <AddressInput
        register={register}
        control={control}
        sectionName={`${
          isWorkersComp ? 'workersCompEmployerAddress' : 'occMedAddress'
        }`}
        setValue={setValue}
        errors={errors}
        setError={setError}
      />
      <AutoCompleteColumn sx={{ mt: 2 }}>
        <DemographicsInnerRow>
          <PhoneNumberInput
            control={control}
            sectionName={`${
              isWorkersComp ? 'workersCompEmployer' : 'occMedEmployer'
            }`}
            required
            error={
              isWorkersComp
                ? errors?.workersCompEmployerPhoneNumber
                : errors?.occMedEmployerPhoneNumber
            }
            setError={setError}
          />
          <div>
            <InputLabel>Email</InputLabel>
            <TextField
              tabIndex={14}
              sx={{ width: '240px' }}
              {...register(
                `${
                  isWorkersComp
                    ? 'workersCompEmployerEmail'
                    : 'occMedEmployerEmail'
                }`,
                {
                  pattern: {
                    value:
                      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                    message: 'Please enter a valid email',
                  },
                }
              )}
              type="email"
              inputProps={{
                'data-testid': `${
                  isWorkersComp
                    ? 'workersCompEmployerEmail'
                    : 'occMedEmployerEmail'
                }`,
              }}
            />
            {!isEmpty(errors?.workersCompEmployerEmail) && (
              <ErrorMessage
                error={errors?.workersCompEmployerEmail.message ?? ''}
              />
            )}
            {!isEmpty(errors?.occMedEmployerEmail) && (
              <ErrorMessage error={errors?.occMedEmployerEmail.message ?? ''} />
            )}
          </div>
        </DemographicsInnerRow>
      </AutoCompleteColumn>
      {isModal && (
        <ModalButtonRow>
          <Box sx={{ flex: 1 }} />
          <ModalButton variant="outlined" type="reset" onClick={handleClose}>
            Cancel
          </ModalButton>
          <ModalButton variant="contained" type="submit">
            Save
          </ModalButton>
        </ModalButtonRow>
      )}
    </DemographicsContainer>
  )
}

