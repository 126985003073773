
import { AiEMCoding } from '@/types/AiEMCoding'
import { Box } from '@mui/material'
import React from 'react'

export const AICodingTile = ({
    codingText,

}: AICodingTileProps): JSX.Element => {

    
    return (
        <>

            {/* <Typography variant="body2" gutterBottom dangerouslySetInnerHTML={{ __html: codingText }} /> */}
            {codingText ? (
                <Box
                    sx={{
                        border: '1px solid #ddd',
                        borderRadius: '8px',
                        padding: '16px',
                        backgroundColor: '#f9f9f9',
                        maxWidth: '800px',
                    }}
                >
                    <p>{codingText.introText}</p>
                    <ul>
                        <li>Number and Complexity of problem(s) addressed during the encounter: <strong>{codingText.encounterSeverityEnum}</strong>
                            <br />{codingText.encounterSeverityReason}
                        </li>
                        <li>Amount and/or Complexity of data to be reviewed and analyzed: <strong>{codingText.dataReviewSeverityEnum}</strong>
                            <br />{codingText.dataReviewSeverityReason}
                        </li>
                        <li>Risk of complications and/or morbidity or mortality of patient management: <strong>{codingText.morbiditySeverityEnum}</strong>
                            <br />{codingText.morbiditySeverityReason}
                        </li>
                    </ul>

                </Box>
            ) : (
                <></>
            )}
        </>

    )
}

interface AICodingTileProps {
    visitId: number
    codingText: AiEMCoding | undefined
}
