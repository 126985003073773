import React from 'react'
import {
  styled,
  Typography,
  Button,
  Popover,
  Paper,
  Stack,
  Dialog,
  type AlertColor,
  Snackbar,
  Alert,
} from '@mui/material'
import { useAuth } from '@clerk/nextjs'
import PopupState, { bindTrigger, bindPopover } from 'material-ui-popup-state'
import { type AccountUser } from '@/types'
import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined'
import FiberPinIcon from '@mui/icons-material/FiberPin'
import KeyIcon from '@mui/icons-material/Key'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import EditPinModal from './EditPinModal'
import { useQueryGetRolePermissions } from '@/hook'
import { isEmpty } from '@/utility'
import { AvatarImage } from '../AvatarImage'
import ResetPasswordModal from './ResetPasswordModal'

const StyledPaper = styled(Paper)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  width: '240px',
  padding: '25px 25px 20px 25px',
  backgroundColor: theme.palette.background.default,
}))
const StyledMenuItem = styled(Typography)({
  display: 'flex',
  alignItems: 'center',
  padding: '10px 0',
  fontSize: '14px',
  borderBottom: '1px solid #DDD',
  cursor: 'pointer',
  gap: '5px',
})
const LogoutButton = styled(Button)({
  fontSize: '14px',
  fontWeight: 'bold',
  marginTop: '15px',
  width: 'fit-content',
  alignContent: 'flex-end',
})
export const UserBanner = ({ currentUser }: { currentUser?: AccountUser }): JSX.Element => {
  const { signOut, getToken } = useAuth()
  const { data: rolePermissions } = useQueryGetRolePermissions(getToken)
  const [isOpenEditPinDialog, setIsOpenEditPinDialog] =
    React.useState<boolean>(false)
  const [isOpenResetPasswordDialog, setIsOpenResetPasswordDialog] =
    React.useState<boolean>(false)
  const [toastMessage, setToastMessage] = React.useState<string>('')
  const [toastSeverity, setToastSeverity] = React.useState<AlertColor>('error')
  const [toastOpen, setToastOpen] = React.useState<boolean>(false)

  const onLogout = (): void => {
    signOut().catch((error) => {
      throw error
    })
  }
  const onEditPIN = (): void => {
    setIsOpenEditPinDialog(true)
  }
  const onResetPassword = (): void => {
    setIsOpenResetPasswordDialog(true)
  }
  const findRole = (role: any): string => {
    if (isEmpty(rolePermissions) || isEmpty(role)) return ''
    return (
      rolePermissions?.find((permission) => permission.id === role[0])?.name ??
      ''
    )
  }

  const handleToastClose = (): void => {
    setToastOpen(false)
    setToastMessage('')
  }

  return (
    <PopupState variant="popover" popupId="actions-popover">
      {(popupState) => (
        <>
          <Button
            style={{
              display: 'flex',
              alignItems: 'center',
              marginLeft: '20px',
              width: 'fit-content',
              border: 'none',
            }}
            {...bindTrigger(popupState)}
          >
            <AvatarImage
              src={
                currentUser?.avatarUrl ??
                `https://ui-avatars.com/api/?format=png&name=${
                  currentUser?.firstName! ?? ''
                }+${currentUser?.lastName! ?? ''}&background=3F888F&color=FFF`
              }
              alt={`${currentUser?.firstName! ?? ''} ${
                currentUser?.lastName! ?? ''
              }`}
              width={28}
              height={28}
              marginRight={'10px'}
              disableFullImage
            />
            <Typography sx={{ mr: 1 }}>
              {`${currentUser?.firstName! ?? ''} ${
                currentUser?.lastName! ?? ''
              }`}
            </Typography>
            <KeyboardArrowDownIcon fontSize="small" sx={{ color: '#8F94B2' }} />
          </Button>
          <Popover
            {...bindPopover(popupState)}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
          >
            <StyledPaper elevation={3}>
              <Stack direction="row">
                <AvatarImage
                  src={
                    currentUser?.avatarUrl ??
                    `https://ui-avatars.com/api/?format=png&name=${
                      currentUser?.firstName! ?? ''
                    }+${
                      currentUser?.lastName! ?? ''
                    }&background=3F888F&color=FFF`
                  }
                  alt={`${currentUser?.firstName! ?? ''} ${
                    currentUser?.lastName! ?? ''
                  }`}
                  width={57}
                  height={57}
                  marginRight={'8px'}
                />
                <Stack direction="column">
                  <Typography
                    style={{
                      fontSize: '20px',
                      fontWeight: 700,
                      textTransform: 'capitalize',
                    }}
                  >{`${currentUser?.firstName! ?? ''} ${
                    currentUser?.lastName! ?? ''
                  }`}</Typography>
                  <Typography sx={{ color: '#777' }}>
                    {findRole(currentUser?.role)}
                  </Typography>
                </Stack>
              </Stack>
              <StyledMenuItem onClick={onEditPIN}>
                <FiberPinIcon fontSize="small" />
                Reset PIN
              </StyledMenuItem>
              <StyledMenuItem onClick={onResetPassword}>
                <KeyIcon fontSize="small" />
                Reset Password
              </StyledMenuItem>
              <LogoutButton variant="contained" onClick={onLogout}>
                <LogoutOutlinedIcon
                  fontSize="small"
                  sx={{ marginRight: '5px' }}
                />
                Logout
              </LogoutButton>
            </StyledPaper>
          </Popover>
          <Dialog open={isOpenEditPinDialog} sx={{ borderRadius: '12px' }}>
            <EditPinModal
              setClose={() => {
                setIsOpenEditPinDialog(false)
              }}
            />
          </Dialog>
          <Dialog
            open={isOpenResetPasswordDialog}
            sx={{ borderRadius: '12px' }}
          >
            <ResetPasswordModal
              user={currentUser}
              setToastMessage={setToastMessage}
              setToastOpen={setToastOpen}
              setToastSeverity={setToastSeverity}
              setClose={() => {
                setIsOpenResetPasswordDialog(false)
              }}
            />
          </Dialog>
          <Snackbar
            open={toastOpen}
            autoHideDuration={2000}
            onClose={handleToastClose}
          >
            <Alert
              onClose={handleToastClose}
              severity={toastSeverity ?? 'error'}
              sx={{ width: '100%' }}
            >
              {toastMessage}
            </Alert>
          </Snackbar>
        </>
      )}
    </PopupState>
  )
}
