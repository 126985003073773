import React from 'react'
import {
  InputLabel,
  Snackbar,
  Alert,
  Divider,
  CircularProgress,
} from '@mui/material'
import { type SubmitHandler, useForm } from 'react-hook-form'
import * as Yup from 'yup'
import { useAuth } from '@clerk/nextjs'
import { yupResolver } from '@hookform/resolvers/yup'
import { isEmpty } from '@/utility'
import {
  ModalContainer,
  ModalRow,
  FullColumn,
  ModalButton,
  ModalTitle,
  ModalFullTextField,
  ModalButtonRow,
  HalfColumn,
  RedText,
  ErrorMessage,
  AddressInput,
  PhoneNumberInput,
} from '@/components'
import { useMutateCreateCompany, useMutateUpdateCompany, useQueryGetStates } from '@/hook'
import { type Company } from '@urgentiq/typesservices'

const validationSchema = (company: any): any =>
  Yup.object().shape({
    companyName: Yup.string().required('Name is required'),
    companyContactName: Yup.string(),
    companystreet1: Yup.string().required('Street1 is required'),
    companystreet2: Yup.string(),
    companycity: Yup.string().required('City is required'),
    companystate: Yup.string().required('State is required'),
    companyzip: Yup.string().required('Zip is required'),
    companyEmail: Yup.string().email('Invalid email'),
    companyPhone: Yup.string().nullable().notRequired(),
    companyFax: Yup.string().nullable().notRequired(),
  })

interface AddCompanyForm {
  companyName?: string | null;
  companyId?: string | null;
  companyContactName?: string | null;
  companystreet1?: string | null;
  companystreet2?: string | null;
  companycity?: string | null;
  companystate?: string | null;
  companyzip?: string | null;
  companyEmail?: string | null;
  companyPhone?: string | null;
  companyFax?: string | null;
}

export const AddCompany = ({
  handleClose,
  company = null,
  refresh,
  onAddCompany,
}: AddCompanyProps): JSX.Element => {
  const { getToken } = useAuth()
  const [isProcessing, setIsProcessing] =
    React.useState<boolean>(false)
  const [toastMessage, setToastMessage] = React.useState<string>('')
  const createCompany = useMutateCreateCompany(getToken)
  const updateCompany = useMutateUpdateCompany(getToken)
  const { data: states } = useQueryGetStates(getToken)
  const {
    register,
    handleSubmit,
    reset,
    control,
    setValue,
    setError,
    formState: { errors },
    trigger,
  } = useForm<AddCompanyForm>({
    mode: 'all',
    resolver: yupResolver(validationSchema(company)),
    ...(company !== null && {
      defaultValues: {
        companyName: company.name,
        companyContactName: company.contactName,
        companystreet1: company.street1,
        companystreet2: company.street2,
        companycity: company.city,
        companystate:
          states?.find((state) => state.id === company?.stateId)?.code ??
          '',
        companyzip: company.zip,
        companyEmail: company.email,
        companyPhone: company.phone,
        companyFax: company.fax,
      },
    }),
  })
  React.useEffect(() => {
    const stateCode =
      states?.find((state) => state.id === company?.stateId)?.code ?? ''
    setValue('companystreet1', company?.street1)
    setValue('companystreet2', company?.street2)
    setValue('companystate', stateCode)
    setValue('companycity', company?.city)
    setValue('companyzip', company?.zip)
  }, [company, states, setValue])

  function isMatch(object: any, source: any): boolean {
    // Iterate over each key in the source object
    for (const key in source) {
      if (Object.prototype.hasOwnProperty.call(source, key)) {
        // If the value is an object, recursively check the nested object
        if (typeof source[key] === 'object' && source[key] !== null) {
          if (!isMatch(object[key], source[key])) {
            return false
          }
        } else {
          // Otherwise, check for equality
          if (object[key] !== source[key]) {
            return false
          }
        }
      }
    }
    return true
  }

  const needUpdateEntity = (
    originCompany: Company | null,
    newCompany: Company
  ): boolean => {
    if (
      originCompany &&
      isMatch(originCompany, newCompany)
    ) {
      return false
    } else {
      return true
    }
  }
  const handleToastClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ): void => {
    if (reason === 'clickaway') {
      return
    }
    setToastMessage('')
  }

  const handleReset = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ): void => {
    event.preventDefault()
    reset()
    handleClose()
  }
  const onSubmit: SubmitHandler<AddCompanyForm> = (data): void => {
    const stateId =
      states?.find((state) => state.code === data.companystate)?.id ?? 0
    const newCompany: Company = {
      name: data.companyName,
      contactName: data.companyContactName,
      id: company?.id ?? 0,
      street1: data.companystreet1,
      street2: data.companystreet2,
      city: data.companycity,
      stateId,
      zip: data.companyzip,
      email: data.companyEmail,
      phone: data.companyPhone ?? '',
      fax: data.companyFax ?? '',
    }
    const shouldUpdateEntity = needUpdateEntity(
      company,
      newCompany
    )
    const response =
      company === null
        ? createCompany.mutateAsync({ company: newCompany })
        : ((company.id && shouldUpdateEntity)
        ? updateCompany.mutateAsync({ id: company.id, company: newCompany })
        : null)
    setIsProcessing(true)
    Promise.resolve(response)
      .then(() => {
        if (refresh) {
          refresh()
        }
        if (onAddCompany) onAddCompany(newCompany)
        handleClose()
      })
      .catch((error) => {
        if (!isEmpty(error)) {
          if (error.includes('=>')) {
            setToastMessage(JSON.parse(error).Message.split('=>')[1])
          } else {
            setToastMessage(error)
          }
        }
        throw error
      })
      .finally(() => {
        setIsProcessing(false)
      })
  }

  const handleSubmitClick = (): void => {
    trigger()
      .then((result) => {
        if (result) {
          handleSubmit(onSubmit)().catch((error) => {
            throw error
          })
        }
      })
      .catch((error) => {
        console.error('Validation failed:', error)
      })
  }

  return (
    <>
      <ModalContainer>
        <form
          style={{ width: '100%' }}
          // I don't put submit button as type="submit" because it triggers submission
          // on parent component's form as well
        >
          <ModalTitle>
            {company === null ? 'Add Company' : 'Edit Company'}
          </ModalTitle>
          <ModalRow>

            <HalfColumn>
              <InputLabel>
                Company Name<RedText>*</RedText>
              </InputLabel>
              <ModalFullTextField
                name={'companyName'}
                register={register}
                error={
                  errors.companyName !== undefined && errors.companyName !== null
                }
              />
              {!isEmpty(errors.companyName) && (
                <ErrorMessage error={errors.companyName?.message ?? ''} />
              )}
            </HalfColumn>
            <HalfColumn>
              <InputLabel>
                Contact Name
              </InputLabel>
              <ModalFullTextField
                name={'companyContactName'}
                register={register}
                error={
                  errors.companyContactName !== undefined && errors.companyContactName !== null
                }
              />
              {!isEmpty(errors.companyContactName) && (
                <ErrorMessage error={errors.companyContactName?.message ?? ''} />
              )}
            </HalfColumn>

          </ModalRow>
          <ModalRow>
            <FullColumn>
              <Divider />
            </FullColumn>
          </ModalRow>
          <ModalRow>
            <FullColumn>
              <AddressInput
                control={control}
                register={register}
                sectionName="company"
                setValue={setValue}
                setError={setError}
                errors={errors}
                fullSize
                required
              />
            </FullColumn>
          </ModalRow>
          <ModalRow>
            <FullColumn>
              <Divider />
            </FullColumn>
          </ModalRow>
          <ModalRow>
          <HalfColumn>
              <InputLabel>
                Email
              </InputLabel>
              <ModalFullTextField
                name={'companyEmail'}
                register={register}
                error={
                  errors.companyEmail !== undefined && errors.companyPhone !== null
                }
              />
              {!isEmpty(errors.companyEmail) && (
                <ErrorMessage error={errors.companyEmail?.message ?? ''} />
              )}
            </HalfColumn>
            <HalfColumn>
              <PhoneNumberInput
                labelName='Phone'
                profile={true}
                sectionName={'companyPhone'}
                error={
                  errors.companyPhone
                }
                setError={setError}
                control={control}
                required={false}
              />
            </HalfColumn>
            <HalfColumn>
              <PhoneNumberInput
                labelName='Fax'
                sectionName={'companyFax'}
                profile={true}
                error={
                  errors.companyFax
                }
                setError={setError}
                control={control}
                required={false}
              />
            </HalfColumn>
          </ModalRow>
          <ModalButtonRow>
            <ModalButton
              variant="outlined"
              type="reset"
              onClick={(event) => handleReset(event)}
            >
              Cancel
            </ModalButton>
            {isProcessing ? (
              <CircularProgress />
            ) : (
              <ModalButton
                variant="contained"
                color="primary"
                onClick={handleSubmitClick}
              >
                {company === null ? 'Add' : 'Save'}
              </ModalButton>
            )}
          </ModalButtonRow>
        </form>
      </ModalContainer>
      <Snackbar
        open={!isEmpty(toastMessage)}
        autoHideDuration={6000}
        onClose={handleToastClose}
      >
        <Alert
          onClose={handleToastClose}
          severity="error"
          sx={{ width: '100%' }}
        >
          {toastMessage}
        </Alert>
      </Snackbar>
    </>
  )
}

interface AddCompanyProps {
  handleClose: any;
  company?: Company | null;
  refresh?: any;
  onAddCompany?: (company: Company) => void;
}
