import React from 'react'
import { useAuth } from '@clerk/nextjs'
import { styled, Tab, Tabs, Badge } from '@mui/material'
import { type Task, type Patient, type PVerifyInsurance, type State } from 'types'
import {
  PatientProfileHistory,
  PatientMedications,
  PatientDemographics,
  PatientVisits,
  PatientInsurances,
  PatientOrders,
  PatientBillingHistory,
  PatientTasks,
} from 'components/PatientProfile'
import { DocumentsTab } from '@/components/ClinicDocuments'
import { useGlobalStore } from '@/hook'
import * as Icons from '@mui/icons-material'
import { useQueryPaginateTasks} from '@/hook/useTasks'

const TabsContainer = styled('div')({ display: 'flex', height: '100%' })

const TabNav = styled(Tabs)({
  paddingTop: '16px',
  paddingLeft: '12px',
  paddingRight: '12px',
  minWidth: '200px',
  '& .MuiTabs-indicatorSpan': {
    backgroundColor: '#4064EE',
    minWidth: '4px',
    maxWidth: '4px',
    minHeight: '42px',
    maxHeight: '42px',
    borderRadius: '8px 0px 0px 8px',
    marginLeft: '-443px',
  },
  '& .MuiTabs-indicator': {
    paddingTop: '1px',
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: 'transparent',
  },
})

const TabItem = styled(Tab)<{ isSelected: boolean }>(({ isSelected }) => ({
  padding: '12px',
  height: '44px !important',
  alignItems: 'center',
  alignContent: 'center',
  justifyContent: 'flex-start',
  marginBottom: '8px',
  cursor: 'pointer',
  color: '#505050',
  width: '225px !important',
  textTransform: 'capitalize',
  flexDirection: 'row',
  backgroundColor: isSelected ? '#E6EBFD' : '#FFFFFF',
  border: '1px solid #DDDFE8',
  borderRadius: '8px',
  '&:hover': { backgroundColor: '#F5F5F5' },
  minHeight: 'unset',
  '& .MuiTab-wrapper': {
    flexDirection: 'row',
    justifyContent: 'flex-start',
  },
}))

const TabContentContainer = styled('div')({ flex: 1, padding: '16px' })

const StyledIcon = styled('span')({
  display: 'inline-flex',
  transform: 'scale(0.8)',
  alignItems: 'center',
  justifyContent: 'center',
  marginTop: '6px',
  marginRight: '8px',
})

const MuiBadge = styled(Badge)(({ theme }) => ({
  '& .MuiBadge-root': {
    right: 22,
    top: '50%',
    transform: 'translateY(-50%)',
  },
  '& .MuiBadge-badge': {
    right: -22,
    top: '50%',
    minWidth: '20px',
    marginTop: '-2px',
    transform: 'translateY(-50%) translateX(50%)',
    background: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
  },
}))

const tabItems = [
  {
    name: 'Demographics',
    icon: Icons.PermIdentityOutlined,
    dataTestId: 'demographicsTab',
  },
  {
    name: 'Insurance',
    icon: Icons.HealthAndSafetyOutlined,
    dataTestId: 'insuranceTab',
  },
  {
    name: 'Medication',
    icon: Icons.MedicationOutlined,
    dataTestId: 'medicationTab',
  },
  { name: 'History', icon: Icons.History, dataTestId: 'historyTab' },
  {
    name: 'Orders & Procedures',
    icon: Icons.NoteAddOutlined,
    dataTestId: 'ordersTab',
  },
  { name: 'Visits', icon: Icons.PeopleOutline, dataTestId: 'visitsTab' },
  {
    name: 'Documents',
    icon: Icons.DescriptionOutlined,
    dataTestId: 'documentsTab',
  },
  {
    name: 'Billing',
    icon: Icons.MonetizationOnOutlined,
    dataTestId: 'billingTab',
  },
  {
    name: 'Tasks',
    icon: Icons.StickyNote2Outlined,
    dataTestId: 'taskPage.itemTab',
  },
]

export const PatientProfileTabs = ({
  patient,
  getPatient,
  states,
  insurancesNames,
  tab,
  isNavOpen,
  clinicZip,
  externalTab,
}: PatientProfileTabsProps): JSX.Element => {
  const { getToken } = useAuth()

  const validTab = tab >= 0 && tab < tabItems.length ? tab : 0

  const [curTab, setCurTab] = React.useState(tabItems[tab].name)
  const [tabValue, setTabValue] = React.useState(validTab)

  const { data: taskPage } = useQueryPaginateTasks(
    getToken,
    undefined,
    undefined,
    patient.id ?? 0
  )
  
  const patientTasksCount = Array.isArray(taskPage?.items)
    ? taskPage?.items?.filter((task: Task) => task.patientId === patient.id && task.statusId !== 3).length
    : 0

  const {
    isEditing,
    setIsEditing,
    setIsUnsavedDataPopupOpen,
    isUnsavedDataPopupOpen,
    setNextLocation,
    nextLocation,
  } = useGlobalStore()

  React.useEffect(() => {
    if (externalTab !== undefined) {
      setTabValue(externalTab)
      setCurTab(tabItems[externalTab].name)
    }
  }, [externalTab])

  React.useEffect(() => {
    setIsEditing(false)
  }, [])

  React.useEffect(() => {
    if (!isUnsavedDataPopupOpen && nextLocation) {
      setCurTab(nextLocation)
      setNextLocation(null)
    }
  }, [isUnsavedDataPopupOpen, nextLocation])

  const handleChange = (
    event: React.SyntheticEvent,
    newValue: number
  ): void => {
    setTabValue(newValue)
    setCurTab(tabItems[newValue].name)
  }

  const handleSelectTab = (tab: string): void => {
    const tabIndex = tabItems.findIndex((item) => item.name === tab)
    if (isEditing) {
      setIsUnsavedDataPopupOpen(true)
      setNextLocation(tab)
    } else {
      setCurTab(tab)
      setTabValue(tabIndex)
      setIsUnsavedDataPopupOpen(false)
      setNextLocation(null)
      setIsEditing(false)
    }
  }

  const renderTabContent = (): JSX.Element => {
    switch (curTab) {
      case 'Demographics':
        return (
          <PatientDemographics
            states={states}
            patient={patient}
            getPatient={getPatient}
            clinicZip={clinicZip}
          />
        )
      case 'Insurance':
        return (
          <PatientInsurances
            patient={patient}
            getPatient={getPatient}
            states={states}
            insurancesNames={insurancesNames}
          />
        )
      case 'Medication':
        return <PatientMedications patient={patient} />
      case 'History':
        return <PatientProfileHistory patient={patient} />
      case 'Orders & Procedures':
        return <PatientOrders patient={patient} />
      case 'Visits':
        return <PatientVisits patient={patient} previousNavOpen={isNavOpen} />
      case 'Documents':
        return <DocumentsTab patient={patient} showAllClinicsOption={false} />
      case 'Billing':
        return <PatientBillingHistory patientId={patient?.id ?? 0} />
      case 'Tasks':
        return <PatientTasks patientId={patient?.id ?? 0} />
      default:
        return <></>
    }
  }

  return (
    <TabsContainer>
      <TabNav
        orientation="vertical"
        value={tabValue}
        onChange={handleChange}
        TabIndicatorProps={{
          children: <span className="MuiTabs-indicatorSpan" />,
        }}
      >
        {tabItems.map(({ name, icon: Icon }, index) => (
          <TabItem
            key={name}
            data-testid={`${name.toLowerCase().replace(/ /g, '')}Tab`}
            isSelected={tabValue === index}
            onClick={() => handleSelectTab(name)}
            icon={
              <StyledIcon>
                <Icon />
              </StyledIcon>
            }
            label={
              <div className="tab-content">
                <span>{name}</span>
                {name === 'Tasks' && (
                  <MuiBadge
                    badgeContent={patientTasksCount ?? 0}
                    color="primary"
                  />
                )}
              </div>
            }
            sx={{ minWidth: '120px' }}
          />
        ))}
      </TabNav>
      <TabContentContainer>{renderTabContent()}</TabContentContainer>
    </TabsContainer>
  )
}

interface PatientProfileTabsProps {
  patient: Patient;
  getPatient: any;
  states: State[];
  insurancesNames: PVerifyInsurance[];
  tab: number;
  isNavOpen: boolean;
  clinicZip: string;
  externalTab?: number;
}
