import { useReferenceDataStore } from '@/hook'
import styled from '@emotion/styled'
import { TextField, Autocomplete } from '@mui/material'
import React from 'react'
import { TileInputLabel } from '../TileInputLabel'
import { dateOnlyToDate, isDateOnlyValid, parseDateToDateOnly } from '@/utility'
import { isBefore } from 'date-fns'
import { type Vital } from '@/types'

const ValueContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  gap: '2px',
})

interface StyledTextFieldProps {
  fullsize: boolean;
  twoColumns: boolean;
  customWidth?: number;
}

const StyledTextField = styled(TextField)(
  ({ fullsize, twoColumns, customWidth }: StyledTextFieldProps) => ({
    width: customWidth? `${customWidth}px` : fullsize ? '766px' : twoColumns ? '506px' : '240px',
    height: '40px !important',
    '& > div': {
      borderRadius: 0,
      height: '40px',
      backgroundColor: 'white',
      '& > input': {
        marginTop: '-2px',
      },
    },
  })
)

interface LMPInputProps {
  vital: Vital
  onUpdate: (data: Partial<Vital>) => void
}

export const LMPInput = ({ vital, onUpdate }: LMPInputProps): JSX.Element => {
  const { menstrualDetails } = useReferenceDataStore()

  const defaultLMP =
    menstrualDetails?.find((md) => md.id === vital?.menstruationDetailsId)?.displayValue ??
    (
      isDateOnlyValid(vital?.lastMenstrualPeriodDate) ?
        dateOnlyToDate(vital?.lastMenstrualPeriodDate)?.toLocaleDateString() : null
    ) ??
    vital?.lastMenstrualPeriod ?? null

  const [inputValue, setInputValue] = React.useState<string | null>(defaultLMP)

  const handleInput = (event: React.SyntheticEvent<Element, Event>, value: string, reason: string): void => {
    switch (reason) {
      case 'clear':
        setInputValue(null)
        onUpdate(
          {
            menstruationDetailsId: null,
            lastMenstrualPeriod: null,
            lastMenstrualPeriodDate: undefined,
          }
        )
        break
      case 'reset':
        setInputValue(value)
        onUpdate(
          {
            menstruationDetailsId: menstrualDetails?.find((md) => md.displayValue === value)?.id ?? undefined,
            lastMenstrualPeriod: undefined,
            lastMenstrualPeriodDate: undefined,
          }
        )
        break
      case 'input': {
        setInputValue(value)
        break
      }
      default:
        break
    }
  }

  const handleBlur = (): void => {
    if (inputValue === vital?.lastMenstrualPeriod
      || inputValue === null
    ) return

    const menstruationDetailsId = menstrualDetails?.find((md) => md.displayValue === inputValue)?.id
    const lastMenstrualPeriodDate = !Number.isNaN(new Date(inputValue).getTime()) && isBefore(new Date(inputValue).getTime(), new Date()) ? parseDateToDateOnly(new Date(inputValue)) : undefined
    const lastMenstrualPeriod = !menstruationDetailsId && !lastMenstrualPeriodDate ? inputValue : null

    const payload = {
      lastMenstrualPeriod,
      menstruationDetailsId,
      lastMenstrualPeriodDate,
    }

    onUpdate(payload)
  }

  return (
    <ValueContainer>
      <div>
        <TileInputLabel>Last Menstrual Period</TileInputLabel>
        <Autocomplete
          freeSolo={inputValue !== null}
          onInputChange={handleInput}
          options={menstrualDetails?.map((md) => md.displayValue ?? '') ?? []}
          componentsProps={{
            paper: {
              style: { marginTop: '6px', marginBottom: '6px' },
            },
          }}
          renderOption={(props, option) => (
            <li
              {...props}
              style={{ width: '766px' }}
              data-testid={option}
            >
              {option}
            </li>
          )}
          renderInput={(params) => (
            <StyledTextField
              {...params}
              fullsize={false}
              twoColumns={false}
              inputProps={{
                ...params.inputProps,
                'data-testId': 'LMPInput',
                value: inputValue ?? '',
                onBlur: handleBlur,
              }}
            />
          )}
        />
      </div>
    </ValueContainer>
  )
}
