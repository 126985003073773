import React, { type ReactNode } from 'react'
import { useAuth, useUser } from '@clerk/nextjs'
import { Stack } from '@mui/system'
import Link from 'next/link'
import {
  IconButton,
  ListItemIcon,
  Menu,
  MenuItem,
  Modal,
  styled,
  TextField,
  type Theme,
  Tooltip,
  Typography,
  useTheme,
  Badge,
  Autocomplete,
  Popper,
  Box,
  CircularProgress
} from '@mui/material'
import { TileTitleBox } from '@/components'
import {
  type AccountUser,
  type DocumoFax,
  type FinancialResponsibleParty,
  type IqBarElasticSearchHttpResponse,
  type Patient,
  type State,
  type AuditRecord,
  type IQBarValue,
  type VisitAccountUserPing
} from '@/types'
import { getDischargeInstructionPrintOff, getDocument } from '@/services'
import {
  useChartTemplateStore,
  useClinicStore,
  useDebounce,
  useIQBar,
  useQueryGetAccountUsers,
  useQueryGetComplaints,
  useQueryGetOccMed,
  useQueryGetPatient,
  useQueryGetPatientAllergy,
  useQueryGetPatientMedication,
  useQueryGetRolePermissions,
  useQueryGetVisitsByPatient,
  useQueryGetVisitTypes,
  useQueryGetWorkersComp,
  useToastStore,
  useVitalsStore
} from '@/hook'
import {
  Letters,
  Visits,
  SendRecords,
  Timestamps,
  OrdersIcon,
  RxIcon,
  AddendumIcon,
  MenuIcon,
  PrintIcon,
  DocumentsIcon,
  WorkersCompIcon,
  PACSIcon,
  NoteAddOutlinedIcon,
  SearchIcon
} from './Icons'
import { formatAge, isEmpty } from '@/utility'
import {
  FinancialResponsiblePartyModal,
  GlobalNote,
  LettersModal,
  VisitsModal,
  PatientDocumentsModal,
  SendRecordModal,
  TimeStampsModal,
  WorkersCompModal
} from './modals'
import { SubmittingModalContainer } from '@/styles'
import WarningAmberIcon from '@mui/icons-material/WarningAmber'
import ErrorIcon from '@mui/icons-material/Error'
import AttachMoneyIcon from '@mui/icons-material/AttachMoney'
import LabelOutlinedIcon from '@mui/icons-material/LabelOutlined'
import ListAltIcon from '@mui/icons-material/ListAlt'
import ContactPageOutlinedIcon from '@mui/icons-material/ContactPageOutlined'
import { EPrescribePopUp } from './ePrescribePopUp'
import { ImportChartDialog } from './ImportChartDialog'

const ModalContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  gap: 12,
  left: '20%',
  position: 'relative',
  width: '550px',
  height: '100px',
  marginTop: '60px',
  marginLeft: '60px',
  paddingLeft: '10px',
  backgroundColor: 'red',
  borderRadius: '20px',
  alignItems: 'baseline',
  justifyContent: 'center',
  color: 'white'
})
const ModalText = styled('h3')({
  display: 'flex',
  alignItems: 'center',
  gap: '5px'
})
const LeftColumn = styled('div')({
  display: 'flex',
  width: '594px',
  gap: '20px'
})
const IQBarContainer = styled('div')(
  ({ isNavOpen, theme }: IQBarContainerProps): any => ({
    display: 'flex',
    width: '100%',
    minHeight: '72px',
    justifyContent: 'flex-start',
    gap: '21px',
    zIndex: 2,
    top: '120px',
    paddingTop: '16px',
    paddingBottom: '16px',
    paddingLeft: '60px',
    left: isNavOpen ? '468px' : '380px',
    position: 'fixed',
    backgroundColor: theme.palette.background.paper
  })
)
const IQBarColumn = styled('div')({
  display: 'flex',
  flexDirection: 'column'
})
const IQBarLogo = styled('div')({
  width: '38px',
  height: '24px',
  background: '#EAF3FF',
  borderRadius: '24px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center'
})
const IQBarText = styled(Typography)({
  fontWeight: 500,
  fontSize: '16px',
  lineHeight: '20px'
})
const MenuText = styled(Typography)({
  fontSize: '14px',
  lineHeight: '20px'
})
const StyledTextField = styled(TextField)(({ theme }): any => ({
  width: '285px',
  height: '39px',
  backgroundColor: theme.palette.background.default,
  border: 'none',
  marginLeft: '2px',
  marginTop: '3px',
  borderRadius: '8px',
  '& .MuiOutlinedInput-root': {
    height: '40px !important',
    borderRadius: '8px',
    borderStyle: 'none',
    justifyContent: 'center',
    alignItems: 'center',
    '& .MuiInputBase-input:first-child': {
      height: '40px !important',
      borderRadius: '8px',
      borderStyle: 'none'
    }
  }
}))
const IconBox = styled('div')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  gap: '16px',
  paddingTop: '8px'
})
const Divider = styled('div')({
  marginTop: '-6px',
  marginRight: '0px',
  marginLeft: '-6px',
  width: '1px',
  height: '20px',
  backgroundColor: '#DDDFE8'
})
const DropDownColumn = styled('div')({
  display: 'flex',
  gap: '12px',
  alignItems: 'center',
  maxWidth: '250px',
  marginLeft: '4px',
  marginTop: '-5px'
})
const DropDownLabel = styled(Typography)({
  fontSize: '14px',
  lineHeight: '20px',
  color: '#252D639'
})
const StyledAutocomplete = styled(Autocomplete)({
  height: '32px',
  fontWeight: 400,
  fontSize: '14px',
  lineHeight: '20px',
  minWidth: '177px',
  maxWidth: '177px',
  backgroundColor: 'white',
  '& .MuiInputBase-input': {
    fontSize: '14px'
  }
})
const StyledPopper = styled(Popper)({
  '& .MuiAutocomplete-groupLabel': {
    height: '35px',
    textTransform: 'uppercase',
    backgroundColor: 'white',
    fontSize: '10px',
    fontWeight: 400,
    borderBottom: '1px solid #DADCEC'
  },
  '.MuiAutocomplete-paper': {
    width: '300px'
  }
})
const UserOnChartContainer = styled(Box)({
  backgroundColor: '#FEF4E6',
  position: 'fixed',
  right: 29,
  top: '150px',
  minWidth: '300px',
  padding: '12px',
  boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
  color: '#6A4A1C'
})

export const IQBar = ({
  setIqBarValues,
  isNavOpen,
  ePrescribeUrl,
  patientId,
  selectedValue,
  globalNote,
  handleSaveGlobalNote,
  patient,
  handlePrintChart,
  auditRecords,
  handleSendFax,
  handleDemoLabel,
  handleDemoPrintOff,
  financialRP,
  handleFinancialResponsibleParty,
  states,
  dx,
  visitId,
  healthGorillaUrl,
  provider,
  printButtonClicked,
  visitType,
  refresh,
  handleChange,
  appliedTemplateId,
  userOnChart,
  reloadSections
}: IQBarProps): JSX.Element => {
  const { getToken } = useAuth()
  const { user } = useUser()
  const theme = useTheme()
  const { clinicId } = useClinicStore()
  const { chiefVital: vitals } = useVitalsStore()
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const menuOpen = Boolean(anchorEl)
  const { data: rolePermissions } = useQueryGetRolePermissions(getToken)
  const [inputValue, setInputValue] = React.useState<string>('')
  const debouncedIQSearchString = useDebounce<string>(inputValue, 500)
  const [isNoteOpen, setIsNoteOpen] = React.useState<boolean>(false)
  const [openePrescribe, setOpenePrescribe] = React.useState<boolean>(false)

  const [isTimestampsOpen, setIsTimestampsOpen] = React.useState<boolean>(false)
  const [isVisitOpen, setIsVisitOpen] = React.useState<boolean>(false)
  const [isSendRecordsOpen, setIsSendRecordsOpen] =
    React.useState<boolean>(false)
  const [isHealthGorillaOpen, setIsHealthGorillaOpen] =
    React.useState<boolean>(false)
  const [isFinancialResponsiblePartyOpen, setIsFinancialResponsiblePartyOpen] =
    React.useState<boolean>(false)
  const [isWorkersCompOpen, setIsWorkersCompOpen] =
    React.useState<boolean>(false)
  const [isLettersOpen, setIsLettersOpen] = React.useState<boolean>(false)
  const [isDocumentsOpen, setIsDocumentsOpen] = React.useState<boolean>(false)
  const [internalNote, setInternalNote] = React.useState<string>('')
  const [generatingDischargeInstruction, setGeneratingDischargeInstruction] =
    React.useState<boolean>(false)
  const patientAge: number = parseInt(
    formatAge(patient?.dateOfBirth).replaceAll('y', '')
  )
  const { addToast } = useToastStore()
  const { data: iqSearchResult } = useIQBar(debouncedIQSearchString, getToken)
  const { data: workComp, refetch: refetchWorkComp } = useQueryGetWorkersComp(
    visitId,
    getToken
  )
  const { data: occMed, refetch: refetchOccMed } = useQueryGetOccMed(
    visitId,
    getToken
  )
  const { data: visitTypes } = useQueryGetVisitTypes(clinicId, getToken)
  const { data: allergies } = useQueryGetPatientAllergy(
    patient?.id!,
    getToken,
    visitId
  )
  const { data: medications } = useQueryGetPatientMedication(
    patient?.id!,
    getToken,
    visitId
  )
  const { data: users } = useQueryGetAccountUsers(getToken)
  const { data: newPatient, refetch: loadPatient } = useQueryGetPatient(
    patient?.id!,
    getToken
  )
  const [templateId, setTemplateId] = React.useState<number>(0)
  const { chartTemplates: templates } = useChartTemplateStore()

  const { data: visits } = useQueryGetVisitsByPatient(patient?.id!, getToken)
  const { data: complaints } = useQueryGetComplaints(getToken)

  const [isChartDialogOpen, setIsChartDialogOpen] =
    React.useState<boolean>(false)

  const handleOpenChartDialog = (): void => {
    setIsChartDialogOpen(true)
  }

  const handleCloseChartDialog = (): void => {
    setIsChartDialogOpen(false)
  }

  const handleDischargeInstructionPrintOff = (): void => {
    setGeneratingDischargeInstruction(true)
    console.log('Generating discharge instructions')
    getDischargeInstructionPrintOff(visitId, getToken)
      .then((res) => {
        if (res !== undefined) {
          getDocument(res, getToken)
            .then((res1) => {
              if (res1 !== undefined) {
                window.open(res1.presignedUrl!)
              }
            })
            .catch((error) => {
              throw error
            })
            .finally(() => {
              setGeneratingDischargeInstruction(false)
            })
        }
      })
      .catch((error: Error) => {
        addToast({
          message: `Error generating discharge instructions: ${error.message}`,
          status: 'error'
        })
      })
  }

  const sortedTemplates = React.useMemo(() => {
    if (templates) {
      const sortedPublicTemplates = templates
        .filter((t) => t.templateIsPublic)
        .sort((a, b) =>
          (a.templateName ?? '').localeCompare(b.templateName ?? '')
        )
      const sortedPrivateTemplates = templates
        .filter((t) => !t.templateIsPublic)
        .sort((a, b) =>
          (a.templateName ?? '').localeCompare(b.templateName ?? '')
        )
      const sorted = [...sortedPrivateTemplates, ...sortedPublicTemplates]
      return sorted
    }
  }, [templates])

  const isWorkersComp = React.useMemo(() => {
    if (!Array.isArray(visitTypes)) return false
    return (
      visitTypes
        .find((type) => type.id === visitType)
        ?.name?.includes('Work') === true
    )
  }, [visitTypes, visitType])

  const isOccMed = React.useMemo(() => {
    return visitType === 4
  }, [visitType])

  const isAllergyMissing = React.useMemo(() => {
    if (!Array.isArray(allergies)) return false
    return allergies.some(
      (allergy) => !allergy?.doseSpotAllergyId || !allergy?.codeType
    )
  }, [allergies])

  const isMedicationsMissing = React.useMemo(() => {
    if (!Array.isArray(medications)) return false
    return medications.some(
      (medication) =>
        !medication?.doseSpotMedicationId || !medication?.dispensableDrugId
    )
  }, [medications])

  const menuItems = React.useMemo(() => {
    const items = [
      {
        icon: <AddendumIcon />,
        text: 'Addendum',
        key: 'addendum'
      },
      {
        icon: <Visits />,
        text: 'Visits',
        key: 'visits'
      },
      {
        icon: <Timestamps />,
        text: 'Timestamps',
        key: 'timestamps'
      },
      {
        icon: <SendRecords />,
        text: 'Send Records',
        key: 'sendRecords'
      },
      {
        icon: (
          <LabelOutlinedIcon sx={{ color: 'black', height: 24, width: 24 }} />
        ),
        text: 'Demographic Label',
        key: 'demographicLabel'
      },
      {
        icon: (
          <ContactPageOutlinedIcon
            sx={{ color: 'black', height: 24, width: 24 }}
          />
        ),
        text: 'Patient Print Off',
        key: 'patientPrintOff'
      },
      {
        icon: <ListAltIcon sx={{ color: 'black', height: 24, width: 24 }} />,
        text: 'Print Discharge Instructions',
        key: 'printDischargeInstructions'
      },
      {
        icon: (
          <AttachMoneyIcon sx={{ color: 'black', height: 24, width: 24 }} />
        ),
        text: 'Financial',
        key: 'financial'
      },
      {
        icon: <WorkersCompIcon />,
        text: isWorkersComp ? "Worker's Comp" : 'Occ Med',
        key: 'workersComp'
      },
      {
        icon: <DocumentsIcon />,
        text: 'Documents',
        key: 'documents'
      }
    ]
    return items.filter(
      (item) => item.key !== 'workersComp' || isWorkersComp || isOccMed
    )
  }, [isWorkersComp, isOccMed])

  React.useEffect(() => {
    setIqBarValues(iqSearchResult as IqBarElasticSearchHttpResponse)
  }, [iqSearchResult])

  const hasLabs = (): boolean => {
    let show = false
    let id = '0'

    if (user?.publicMetadata?.account_user_id) {
      id = user.publicMetadata.account_user_id as string
    }

    const uIUser: AccountUser | undefined = Array.isArray(users)
      ? users.find((u) => u.id === parseInt(id))
      : undefined
    const userRole: number[] = user?.publicMetadata?.role as number[]
    const permissions = Array.isArray(rolePermissions)
      ? rolePermissions.find((rp) => rp.id === userRole[0])
      : undefined
    const isAllowed =
      (permissions?.permissionList as any[])?.findIndex(
        (permission) => permission.id === 74
      ) > -1
    show = isAllowed && !isEmpty(uIUser?.healthGorillaPractitionerId)

    return show
  }

  const hasPastVisitValue =
    visits?.some((visit) => visit.id !== visitId) || false

  const RXElement = React.useMemo((): JSX.Element => {
    const isPatientUnderage = patientAge <= 18
    const isWeightMissing = !vitals?.weight || vitals.weight.toString() === '0'
    const isHeightMissing =
      (!vitals?.heightFeet || vitals.heightFeet.toString() === '0') &&
      (!vitals?.heightInches || vitals.heightInches.toString() === '0')

    if (isPatientUnderage && (isWeightMissing || isHeightMissing)) {
      return (
        <ModalContainer>
          <ModalText>
            <ErrorIcon sx={{ color: 'white' }} /> Height and weight must be
            filled in for patients under 19.
          </ModalText>
        </ModalContainer>
      )
    }
    let checkedPatientId = patientId
    if (patientId === 0 || patientId === undefined || patientId === null) {
      // refetch patient
      Promise.resolve(loadPatient()).catch((err) => {
        throw err
      })
      checkedPatientId = newPatient?.doseSpotPatientId!
    }
    return (
      <EPrescribePopUp
        url={ePrescribeUrl}
        patientId={checkedPatientId}
      />
    )
  }, [patientAge, vitals, patientId, ePrescribeUrl, loadPatient, newPatient])

  const handleInputChange = (event: any): void => {
    if (event.target.value !== null) {
      setInputValue(event.target.value)
    }
  }

  const handleTemplateChange = (_event: any, value: any) => {
    if (value?.id) {
      setTemplateId(value.id)
      handleChange(value.id)
    } else {
      setTemplateId(0)
    }
  }

  const handleClickListItem = (event: React.MouseEvent<HTMLElement>): void => {
    setAnchorEl(event.currentTarget)
  }
  const handleSaveFinancialResponsibleParty = (
    newFPR: FinancialResponsibleParty
  ): void => {
    if (financialRP !== null) {
      handleFinancialResponsibleParty(newFPR)
    }
    setIsFinancialResponsiblePartyOpen(false)
  }
  React.useEffect(() => {
    setInternalNote(globalNote)
  }, [globalNote])
  React.useEffect(() => {
    if (appliedTemplateId !== 0) {
      setTemplateId(appliedTemplateId)
    }
  }, [appliedTemplateId])
  const handleMenuItemClick = (
    event: React.MouseEvent<HTMLElement>,
    index: number
  ): void => {
    setAnchorEl(null)
    if (index === 0) {
      setIsNoteOpen(true)
    } else if (index === 1) {
      setIsVisitOpen(true)
    } else if (index === 2) {
      setIsTimestampsOpen(true)
    } else if (index === 3) {
      setIsSendRecordsOpen(true)
    } else if (index === 4) {
      handleDemoLabel()
    } else if (index === 5) {
      handleDemoPrintOff()
    } else if (index === 6) {
      handleDischargeInstructionPrintOff()
    } else if (index === 7) {
      setIsFinancialResponsiblePartyOpen(true)
    } else if (index === 8 && (isWorkersComp || isOccMed)) {
      refetchWorkComp().catch((err) => {
        throw err
      })
      refetchOccMed().catch((err) => {
        throw err
      })
      setIsWorkersCompOpen(true)
    } else if (index === 9 || (index === 8 && !isWorkersComp && !isOccMed)) {
      setIsDocumentsOpen(true)
    }
  }
  const handleClose = (): void => {
    setAnchorEl(null)
  }
  React.useEffect(() => {
    if (selectedValue?.fieldName !== '') {
      setInputValue('')
      setIqBarValues({})
    }
  }, [selectedValue?.fieldName])

  const start: ReactNode = (
    <IQBarLogo>
      <IQBarText>IQ</IQBarText>
    </IQBarLogo>
  )

  return (
    <IQBarContainer
      theme={theme}
      isNavOpen={isNavOpen}
    >
      {' '}
      <Stack
        direction="column"
        spacing={2}
      >
        <Stack
          direction="row"
          spacing={2}
        >
          <Modal
            open={isHealthGorillaOpen}
            onClose={() => setIsHealthGorillaOpen(false)}
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center'
            }}
          >
            <EPrescribePopUp
              url={healthGorillaUrl}
              type="healthGorilla"
            />
          </Modal>
          <Modal
            open={openePrescribe}
            onClose={() => setOpenePrescribe(false)}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              paddingLeft: '358px'
            }}
          >
            {RXElement}
          </Modal>
          <Modal
            open={isNoteOpen}
            onClose={() => setIsNoteOpen(false)}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center'
            }}
          >
            <GlobalNote
              saveNote={handleSaveGlobalNote}
              note={internalNote}
              handleClose={() => setIsNoteOpen(false)}
            />
          </Modal>
          <Modal
            open={isTimestampsOpen}
            onClose={() => setIsTimestampsOpen(false)}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center'
            }}
          >
            <TimeStampsModal
              auditRecords={auditRecords}
              users={users ?? []}
              handleClose={() => setIsTimestampsOpen(false)}
            />
          </Modal>
          <Modal
            open={isVisitOpen}
            onClose={() => setIsVisitOpen(false)}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center'
            }}
          >
            <VisitsModal
              patient={patient}
              handleClose={() => setIsVisitOpen(false)}
            />
          </Modal>
          <Modal
            open={isSendRecordsOpen}
            onClose={() => setIsSendRecordsOpen(false)}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center'
            }}
          >
            <SendRecordModal
              handleSend={handleSendFax}
              handleClose={() => setIsSendRecordsOpen(false)}
            />
          </Modal>
          <Modal
            open={isLettersOpen}
            onClose={() => setIsLettersOpen(false)}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center'
            }}
          >
            <LettersModal
              visitId={visitId}
              dx={dx}
              provider={provider}
              handleClose={() => setIsLettersOpen(false)}
              patient={patient}
              states={states}
            />
          </Modal>
          <Modal
            open={isFinancialResponsiblePartyOpen}
            onClose={() => setIsFinancialResponsiblePartyOpen(false)}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center'
            }}
          >
            <FinancialResponsiblePartyModal
              handleClose={() => setIsFinancialResponsiblePartyOpen(false)}
              handleSave={handleSaveFinancialResponsibleParty}
              financialRP={financialRP}
              states={states}
              visitType={visitType}
            />
          </Modal>
          <Modal
            open={isDocumentsOpen}
            onClose={() => setIsDocumentsOpen(false)}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center'
            }}
          >
            <PatientDocumentsModal
              handleClose={() => setIsDocumentsOpen(false)}
              patient={patient}
              clinicId={clinicId}
              visitId={visitId}
            />
          </Modal>
          <Modal
            open={isWorkersCompOpen}
            onClose={() => setIsWorkersCompOpen(false)}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center'
            }}
          >
            <WorkersCompModal
              visitId={visitId}
              visitType={visitType}
              data={isWorkersComp ? workComp : occMed}
              handleClose={() => setIsWorkersCompOpen(false)}
              refresh={refresh}
            />
          </Modal>
          <Modal
            open={generatingDischargeInstruction}
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-start',
              paddingLeft: isNavOpen ? '862px' : '782px',
              paddingTop: '-200px'
            }}
          >
            <SubmittingModalContainer>
              <TileTitleBox tileTitle="Printing Discharge Instructions" />
              <CircularProgress />
            </SubmittingModalContainer>
          </Modal>
          <Menu
            id="lock-menu"
            anchorEl={anchorEl}
            open={menuOpen}
            onClose={handleClose}
            sx={{ width: '270px', borderRadius: '8px' }}
            MenuListProps={{
              'aria-labelledby': 'lock-button',
              role: 'listbox'
            }}
          >
            {menuItems.map((item, index) => (
              <MenuItem
                key={index}
                sx={{ width: '240px', height: '42px' }}
                onClick={(event) => handleMenuItemClick(event, index)}
              >
                <ListItemIcon>{item.icon}</ListItemIcon>
                <MenuText>{item.text}</MenuText>
              </MenuItem>
            ))}
            <Link
              href={'https://webapp.comiere.com/'}
              target={'_blank'}
            >
              <MenuItem
                sx={{ width: '240px', height: '42px' }}
                onClick={handleClose}
              >
                <ListItemIcon>
                  <PACSIcon />
                </ListItemIcon>
                <MenuText>PACS</MenuText>
              </MenuItem>
            </Link>
          </Menu>
          <IQBarColumn>
            <LeftColumn>
              <StyledTextField
                disabled={true}
                value={inputValue}
                onChange={handleInputChange}
                InputProps={{
                  startAdornment: start,
                  endAdornment: <SearchIcon />
                }}
              />
              <DropDownColumn>
                <DropDownLabel
                  sx={{
                    marginLeft: '-2px',
                    marginRight: '-2px',
                    marginTop: '6px'
                  }}
                >
                  Template
                </DropDownLabel>
                <StyledAutocomplete
                  options={sortedTemplates ?? []}
                  groupBy={(option: any) =>
                    option.templateIsPublic
                      ? 'Public Templates'
                      : 'Private Templates'
                  }
                  getOptionLabel={(option: any) => option.templateName}
                  onChange={handleTemplateChange}
                  renderInput={(params: any) => (
                    <TextField
                      {...params}
                      variant="outlined"
                    />
                  )}
                  value={templates?.find((t) => t.id === templateId) ?? null}
                  isOptionEqualToValue={(option: any, value: any) =>
                    option.id === value.id
                  }
                  PopperComponent={StyledPopper}
                  onBlur={() => setTemplateId(appliedTemplateId)}
                  disableClearable={true}
                  sx={{ marginLeft: '4px', marginTop: '4px' }}
                />
              </DropDownColumn>
            </LeftColumn>
          </IQBarColumn>
          <IconBox>
            <Tooltip
              title={
                <div style={{ textAlign: 'center' }}>
                  {hasPastVisitValue
                    ? 'Import from past visit'
                    : 'No Past Visits'}
                </div>
              }
              PopperProps={{
                modifiers: [
                  {
                    name: 'offset',
                    options: {
                      offset: [-20, -4]
                    }
                  }
                ]
              }}
            >
              <span>
                <IconButton
                  sx={{
                    width: '32px',
                    height: '32px',
                    marginTop: '-8px',
                    marginLeft: '-38px',
                    '&.Mui-disabled': {
                      cursor: 'not-allowed',
                      pointerEvents: 'auto'
                    }
                  }}
                  disabled={!hasPastVisitValue}
                  onClick={
                    hasPastVisitValue ? handleOpenChartDialog : undefined
                  }
                  size="large"
                >
                  <NoteAddOutlinedIcon data-testid="importPastVisitButton" />
                </IconButton>
              </span>
            </Tooltip>
            <ImportChartDialog
              patient={patient}
              visitId={visitId}
              complaints={complaints}
              open={isChartDialogOpen}
              onClose={handleCloseChartDialog}
              reloadSections={reloadSections}
            />
            <Tooltip
              title={
                hasLabs() ? (
                  'Labs'
                ) : (
                  <div style={{ textAlign: 'center' }}>
                    No Labs Available: <br />
                    Contact Customer Support
                  </div>
                )
              }
              PopperProps={{
                modifiers: [
                  {
                    name: 'offset',
                    options: {
                      offset: [-6, -4]
                    }
                  }
                ]
              }}
            >
              <span>
                <IconButton
                  sx={{
                    width: '32px',
                    height: '32px',
                    marginTop: '-8px',
                    marginLeft: '-14px'
                  }}
                  disabled={!hasLabs()}
                  onClick={() => setIsHealthGorillaOpen(true)}
                  size="large"
                >
                  <OrdersIcon data-testid="healthGorillaButton" />
                </IconButton>
              </span>
            </Tooltip>
            <Tooltip
              title={
                isAllergyMissing || isMedicationsMissing ? (
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      gap: '5px'
                    }}
                  >
                    <div style={{ fontWeight: 'bold', fontSize: '14px' }}>
                      Warning:
                    </div>
                    {isMedicationsMissing && (
                      <div>
                        Medications:{' '}
                        {medications
                          ?.filter(
                            (item) =>
                              !item?.doseSpotMedicationId ||
                              !item?.dispensableDrugId
                          )
                          ?.map((med) => med?.medicationName)
                          ?.join(', ')}{' '}
                        will not provide interaction alerts in DoseSpot due to
                        insufficient information.
                      </div>
                    )}
                    {isAllergyMissing && (
                      <div>
                        Allergies:{' '}
                        {allergies?.map((allergy) => allergy?.name)?.join(', ')}{' '}
                        will not provide interaction alerts in DoseSpot due to
                        insufficient information.
                      </div>
                    )}
                  </div>
                ) : (
                  'Rx'
                )
              }
            >
              <Badge
                color="primary"
                badgeContent={
                  isAllergyMissing || isMedicationsMissing ? '!' : ''
                }
                invisible={!isAllergyMissing && !isMedicationsMissing}
                sx={{
                  '& .MuiBadge-badge': {
                    fontSize: '0.6rem',
                    minWidth: '16px',
                    height: '16px'
                  }
                }}
              >
                <IconButton
                  sx={{
                    width: '24px',
                    height: '24px',
                    marginTop: '-8px',
                    marginLeft: '-10px'
                  }}
                  onClick={() => setOpenePrescribe(true)}
                  size="large"
                >
                  <RxIcon />
                </IconButton>
              </Badge>
            </Tooltip>
            <Tooltip title="Letters & Documents">
              <IconButton
                sx={{
                  width: '24px',
                  height: '24px',
                  marginTop: '-8px',
                  marginLeft: '0px'
                }}
                onClick={() => setIsLettersOpen(true)}
                size="large"
              >
                <Letters />
              </IconButton>
            </Tooltip>
            <IconButton
              sx={{
                width: '32px',
                height: '32px',
                marginTop: '-8px',
                marginLeft: '-10px'
              }}
              onClick={handleClickListItem}
              size="large"
            >
              <MenuIcon />
            </IconButton>
            <Divider />
            <Tooltip
              title="Print Chart"
              PopperProps={{
                modifiers: [
                  {
                    name: 'offset',
                    options: {
                      offset: [0, -4]
                    }
                  }
                ]
              }}
            >
              <IconButton
                data-testid="print-chart-button"
                disabled={printButtonClicked}
                sx={{
                  width: '32px',
                  height: '32px',
                  marginTop: '-8px',
                  marginLeft: '-8px'
                }}
                onClick={handlePrintChart}
                size="large"
              >
                <PrintIcon data-testid="print-chart-icon" />
              </IconButton>
            </Tooltip>
          </IconBox>
        </Stack>
        <UserOnChartContainer
          sx={{ display: `${userOnChart?.length > 1 ? 'block' : 'none'}` }}
        >
          <Typography
            sx={{ paddingRight: 1, fontWeight: 'bold', paddingTop: '6px' }}
          >
            <IconButton
              color="warning"
              size="small"
              sx={{
                paddingRight: '10px',
                paddingBottom: '8px',
                '& .MuiSvgIcon-root': {
                  fontSize: '1rem'
                }
              }}
            >
              <WarningAmberIcon />
            </IconButton>
            Multiple Users in Chart:
          </Typography>
          <Stack
            direction="column"
            spacing={2}
            sx={{ paddingLeft: 4, paddingBottom: 1 }}
          >
            <Typography>
              {userOnChart
                ?.map((user, index, array) => {
                  const fullUser = users?.find(
                    (u) => u.id === user.readOnlyAccountUserId
                  )
                  const userFullName = `${fullUser?.firstName ?? ''} ${
                    fullUser?.lastName ?? ''
                  }`.trim()

                  if (index === array.length - 1) {
                    return userFullName
                  } else {
                    return `${userFullName}, `
                  }
                })
                .join('')}
            </Typography>
          </Stack>
        </UserOnChartContainer>
      </Stack>
    </IQBarContainer>
  )
}

interface IQBarProps {
  visitId: number
  isNavOpen: boolean
  setIqBarValues: (values: IqBarElasticSearchHttpResponse) => void
  ePrescribeUrl: string
  patientId: number
  selectedValue: IQBarValue
  globalNote: string
  handleSaveGlobalNote: (note: string) => void
  patient: Patient
  handlePrintChart: () => void
  auditRecords: AuditRecord[]
  handleSendFax: (fax: DocumoFax) => void
  handleDemoLabel: () => void
  handleDemoPrintOff: () => void
  financialRP: FinancialResponsibleParty
  handleFinancialResponsibleParty: (
    financialRP: FinancialResponsibleParty
  ) => void
  states: State[]
  dx: string
  provider: string
  healthGorillaUrl: string
  printButtonClicked: boolean
  visitType: number
  refresh: any
  clinicId: number
  handleChange: (index: number) => void
  appliedTemplateId: number
  userOnChart: VisitAccountUserPing[]
  reloadSections: () => void
}

interface IQBarContainerProps {
  isNavOpen: boolean
  theme: Theme
}
