import {
  Chip,
  Divider,
  IconButton,
  LinearProgress,
  Link,
  Menu,
  MenuItem,
  Popover,
  styled,
  type Theme,
  Tooltip,
  Typography,
  useTheme,
  Modal,
} from '@mui/material'
import { useAuth } from '@clerk/nextjs'
import { useQueryGetAccountUsers, useMutateUpdateVisit, useVitalsStore, useQueryGetProcedure } from '@/hook'
import React from 'react'
import {
  type Complaint,
  type Visit,
  type VisitType,
  type Patient,
  type ChiefComplaint,
  type VitalDetail
} from '@/types'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import AddCircleOutlineRoundedIcon from '@mui/icons-material/AddCircleOutlineRounded'
import {
  formatDistanceStrict,
  formatDistanceToNowStrict,
  addMinutes,
} from 'date-fns'
import { AllergyPopover } from './AllergyPopover'
import {
  isEmpty,
  formatDateOnly,
  isDateOnlyValid,
  formatAgeWithRules,
} from '@/utility/utils'
import { AvatarImage } from '@/components/AvatarImage'
import { ClockIcon, ProviderIcon, RoomIcon } from '../Editor/IQBar/Icons'
import FeedOutlinedIcon from '@mui/icons-material/FeedOutlined'
import { StickyNotesModal } from '@/components/StickyNotesModal'
import { formatInUTC } from '@/utility'
const ProfilePanelContainer = styled('div')(
  ({ isNavOpen, theme }: ProfilePanelContainerProps) => ({
    /* Auto layout */
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    alignContent: 'center',
    padding: '10px 20px',
    position: 'fixed',
    width: '100vw',
    height: '62px',
    background: theme.palette.background.default,
    borderBottom: '1px solid #E6E7EF',
    boxShadow: '0px 4px 4px rgba(64, 71, 118, 0.05)',
    zIndex: 11,
    marginTop: '60px',
    ...(isNavOpen && { marginLeft: '80px' }),
    ...(isNavOpen && { paddingRight: '100px' }),
  })
)
const MenuText = styled(Typography)({
  fontSize: '14px',
  lineHeight: '20px',
  color: 'black',
})
const OuterColumn = styled('div')({
  gap: '12px',
  display: 'flex',
})

const PatientPhotoNameContainer = styled('div')({
  display: 'flex',
  gap: '6px',
})

const VisitStatContainer = styled('div')({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  alignContent: 'center',
})

const PatientNameContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  gap: '2px',
  borderRight: '1px solid #E6E7EF',
  paddingRight: '12px',
  justifyContent: 'center',
})
const PatientNameText = styled(Typography)(({ theme }): any => ({
  fontSize: '16px',
  fontWeight: 700,
  lineHeight: '19.2px',
  color: 'black',
}))
const PatientInfoText = styled(Typography)(({ theme }): any => ({
  fontSize: '14px',
  lineHeight: '16.8px',
  color: 'black',
}))
const ColumnTitle = styled(Typography)(({ theme }): any => ({
  fontSize: '12px',
  lineHeight: '16px',
  letterSpacing: '0.01em',
  color: '#717899',
}))
const ColumnValue = styled(Typography)(({ theme }): any => ({
  fontSize: '14px',
  fontWeight: 500,
  lineHeight: '16px',
  color: 'black',
  textTranform: 'capitalize !important',
  minHeight: '16px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}))
const TruncatedValue = styled(Typography)({
  fontSize: '14px',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  width: 100,
})
const SpaceValue = styled(Typography)(({ theme }): any => ({
  width: '40px',
  fontSize: '14px',
  fontWeight: 500,
  lineHeight: '16px',
  color: 'black',
  textTranform: 'capitalize !important',
  minHeight: '16px',
  display: 'block',
  padding: 5,
}))
const CountCircle = styled('div')(({ theme }): any => ({
  width: '24px',
  height: '24px',
  borderRadius: '50%',
  background: '#EAF3FF',
  fontWeight: 700,
  fontSize: '10x',
  lineHeight: '12px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  color: '252D63',
  marginTop: '-4px',
}))

const AllergiesContainer = styled('div')({
  display: 'flex',
  gap: '4px',
})

const ChipContainer = styled('div')({
  display: 'flex',
  gap: '4px',
  flexWrap: 'wrap',
  padding: '4px',
  justifyContent: 'center',
  marginTop: '4px',
})

const StyledChip = styled(Chip)(({ theme }) => ({
  backgroundColor: '#CFD8FD',
  color: '#000',
  fontSize: '14px',
  lineHeight: 'normal',
  height: '26px',
}))

// prettier-ignore
export const ProfilePanel = ({
  isNavOpen,
  visit,
  visitTypes,
  complaints,
  allergies,
  provider,
  room,
  patient,
  chiefComplaint,
  employerName,
  isLoading,
  refresh
}: ProfilePanelProps): JSX.Element => {
  const theme = useTheme()
  const { getToken } = useAuth()
  const { chiefVital: vitals } = useVitalsStore()
  const { refetch: fetchProcedure } = useQueryGetProcedure(visit?.id ?? 0, getToken)
  const [menuAnchorEl, setMenuAnchorEl] = React.useState<null | HTMLElement>(null)
  const [providerMenuAnchorEl, setProviderMenuAnchorEl] = React.useState<null | HTMLElement>(null)
  const menuOpen = Boolean(menuAnchorEl)
  const providerMenuOpen = Boolean(providerMenuAnchorEl)
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null)
  const [showAllergies, setShowAllergies] = React.useState<boolean>(false)
  const [complaintNames, setComplaintNames] = React.useState<string[]>([])
  const [gender, setGender] = React.useState<string>('')
  const [avatarUrl, setAvatarUrl] = React.useState<string>('')
  const [timeSinceArrival, setTimeSinceArrival] = React.useState<string>('')
  const [stickyNotesOpen, setStickyNotesOpen] = React.useState<boolean>(false)
  const { data: clinicians } = useQueryGetAccountUsers(getToken)
  const updateVisitMutation = useMutateUpdateVisit(getToken)
  const [shouldShowAddProviderIcon, setShouldShowAddProviderIcon] = React.useState<boolean>(false)
  const [mostRecentDetail, setMostRecentDetail] = React.useState<VitalDetail>({})
  const providers = clinicians?.filter(
    (clinician) =>
      clinician.role?.includes(2) === true ||
      clinician.npiNumber
  ).sort((a, b) => (a.firstName ?? '').localeCompare(b.firstName ?? '')) ?? []
  const isWorkersComp = React.useMemo(() => {
    return visitTypes?.find(type => type.id === chiefComplaint?.visitTypeId)?.name?.includes('Work') === true
  }, [visitTypes, chiefComplaint])

  const isOccMed = React.useMemo(() => {
    return chiefComplaint?.visitTypeId === 4
  }, [chiefComplaint])

  const handleClose = (): void => {
    setMenuAnchorEl(null)
  }
  const handleCloseProviderMenu = (): void => {
    setProviderMenuAnchorEl(null)
    setShouldShowAddProviderIcon(false)
  }
  const handleClickListItem = (event: React.MouseEvent<HTMLElement>): void => {
    setMenuAnchorEl(event.currentTarget)
  }
  const handleSelectProvider = (event: React.MouseEvent<HTMLElement>): void => {
    setProviderMenuAnchorEl(event.currentTarget)
  }

  React.useEffect(() => {
    if (!vitals?.vitalDetails || vitals.vitalDetails.length === 0) return

    const details = [...vitals.vitalDetails]

    const mostRecentBPDetail = {
      bpSystolic: 0,
      bpDiastolic: 0,
      bloodPressureOutOfRange: false,
      pulse: 0,
      pulseOutOfRange: false,
      temperatureOutOfRange: false,
      respiratoryRateOutOfRange: false,
      oxygenSaturationOutOfRange: false
    }

    details.forEach((detail) => {
      if (
        detail.bpSystolic !== undefined &&
        detail.bpSystolic !== null &&
        detail.bpSystolic > 0 &&
        detail.bpDiastolic !== undefined &&
        detail.bpDiastolic !== null &&
        detail.bpDiastolic > 0
      ) {
        mostRecentBPDetail.bpSystolic = detail.bpSystolic
        mostRecentBPDetail.bpDiastolic = detail.bpDiastolic
        mostRecentBPDetail.bloodPressureOutOfRange = detail.bloodPressureOutOfRange ?? false
      }

      if (detail.pulse !== undefined && detail.pulse !== null && detail.pulse > 0) {
        mostRecentBPDetail.pulse = detail.pulse
        mostRecentBPDetail.pulseOutOfRange = detail.pulseOutOfRange ?? false
      }

      if (detail.temperature !== undefined && detail.temperature !== null && detail.temperature > 0) {
        mostRecentBPDetail.temperatureOutOfRange = detail.temperatureOutOfRange ?? false
      }

      if (detail.respiratoryRate !== undefined && detail.respiratoryRate !== null && detail.respiratoryRate > 0) {
        mostRecentBPDetail.respiratoryRateOutOfRange = detail.respiratoryRateOutOfRange ?? false
      }
    }
    )

    const lastCompleteBPIndex = details.reverse().findIndex((detail) => {
      return detail.bpSystolic !== undefined &&
        detail.bpSystolic !== null &&
        detail.bpSystolic > 0 &&
        detail.bpDiastolic !== undefined &&
        detail.bpDiastolic !== null &&
        detail.bpDiastolic > 0
    }) ?? 0

    const newBpSystolic = details[lastCompleteBPIndex]?.bpSystolic ?? 0
    const newBpDiastolic = details[lastCompleteBPIndex]?.bpDiastolic ?? 0
    const newBpOutOfRange = details[lastCompleteBPIndex]?.bloodPressureOutOfRange ?? false

    const lastRecordedTempIndex = details.findIndex((detail) => !isEmpty(detail.temperature) && (detail?.temperature ?? 0) > 0)
    const newTemp = details[lastRecordedTempIndex]?.temperature ?? 0
    const newTempOutOfRange = details[lastRecordedTempIndex]?.temperatureOutOfRange ?? false

    const lastRecordedOxygenIndex = details.findIndex((detail) => !isEmpty(detail.oxygenSaturation) && (detail?.oxygenSaturation ?? 0) > 0)
    const newOxygen = details[lastRecordedOxygenIndex]?.oxygenSaturation ?? 0
    const newOxygenOutOfRange = details[lastRecordedOxygenIndex]?.oxygenSaturationOutOfRange ?? false

    const lastRecordedPulseIndex = details.findIndex((detail) => !isEmpty(detail.pulse) && (detail?.pulse ?? 0) > 0)
    const newPulse = details[lastRecordedPulseIndex]?.pulse ?? 0
    const newPulseOutOfRange = details[lastRecordedPulseIndex]?.pulseOutOfRange ?? false

    const lastRecodedRespiratoryRateIndex = details.findIndex((detail) => !isEmpty(detail.respiratoryRate) && (detail?.respiratoryRate ?? 0) > 0)
    const newRespiratoryRate = details[lastRecodedRespiratoryRateIndex]?.respiratoryRate ?? 0
    const newRespiratoryRateOutOfRange = details[lastRecodedRespiratoryRateIndex]?.respiratoryRateOutOfRange ?? false

    const newAggregateDetail: VitalDetail = {
      bpSystolic: newBpSystolic,
      bpDiastolic: newBpDiastolic,
      bloodPressureOutOfRange: newBpOutOfRange,
      temperature: newTemp,
      temperatureOutOfRange: newTempOutOfRange,
      pulse: newPulse,
      pulseOutOfRange: newPulseOutOfRange,
      respiratoryRate: newRespiratoryRate,
      respiratoryRateOutOfRange: newRespiratoryRateOutOfRange,
      oxygenSaturation: newOxygen,
      oxygenSaturationOutOfRange: newOxygenOutOfRange,
      vitalDateTime: details.reduce<string | null>((acc, cur) => cur.vitalDateTime ?? acc ?? null, null) ?? null,
      vitalNote: details.map((detail) => detail.vitalNote).join(' \n')
    }
    setMostRecentDetail(newAggregateDetail)
  }, [vitals?.vitalDetails])

  React.useEffect(() => {
    if (
      chiefComplaint?.complaintList === null ||
      chiefComplaint?.complaintList === undefined ||
      chiefComplaint?.complaintList.length === 0
    ) {
      setComplaintNames([''])
    } else {
      const names: string[] = chiefComplaint?.complaintList?.map(
        (complaintId: number) => {
          return (
            complaints?.find((complaint: Complaint) => {
              return complaint.id === complaintId
            })?.name ?? ''
          )
        }
      )
      setComplaintNames(names ?? [''])
    }
  }, [chiefComplaint?.complaintList, complaints, setComplaintNames])

  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>): void => {
    if (event.currentTarget.id === 'allergies') {
      setShowAllergies(true)
    } else {
      setShowAllergies(false)
    }
    setAnchorEl(event.currentTarget)
  }

  const handlePopoverClose = (): void => {
    setAnchorEl(null)
  }

  const open = Boolean(anchorEl)
  React.useEffect(() => {
    findGender()
    setAvatarUrl(patient?.avatarUrl ?? '/user.png')
  })
  const findGender = (): void => {
    if (patient?.sex === undefined || patient?.sex === null) {
      setGender('')
    } else if (patient?.sex === 'NoResponse') {
      setGender('NR')
    } else {
      setGender(patient?.sex.slice(0, 1))
    }
  }

  const findTime = (): string => {
    if (
      visit?.arrivalDateTime === undefined ||
      visit?.arrivalDateTime === null
    ) {
      return 'min'
    } else if (
      visit?.dischargeDateTime === undefined ||
      visit?.dischargeDateTime === null
    ) {
      const unformattedDate = formatDistanceToNowStrict(
        new Date(visit?.arrivalDateTime),
        { roundingMethod: 'floor', unit: 'minute' }
      )
      return `${unformattedDate.replace(' minute', 'min')}`
    } else {
      const unformattedDate = formatDistanceStrict(
        new Date(visit?.dischargeDateTime),
        new Date(visit?.arrivalDateTime),
        { roundingMethod: 'floor', unit: 'minute' }
      )
      return `${unformattedDate.replace(' minute', 'min')}`
    }
  }
  React.useEffect(() => {
    setTimeSinceArrival(findTime())

    const intervalId = setInterval(() => {
      const time = findTime()
      setTimeSinceArrival(time)
    }, 30000)
    return () => clearInterval(intervalId)
  }, [visit?.arrivalDateTime, visit?.dischargeDateTime, findTime])

  const bpPulseColumns = (): JSX.Element => {
    if (!vitals?.vitalDetails || vitals?.vitalDetails?.length === 0) {
      return (
        <>
          <PatientNameContainer>
            <ColumnTitle>BP</ColumnTitle>
            <ColumnValue />
          </PatientNameContainer>
          <PatientNameContainer>
            <ColumnTitle>Pulse</ColumnTitle>
            <ColumnValue />
          </PatientNameContainer>
          <PatientNameContainer>
            <ColumnTitle>Resp.</ColumnTitle>
            <ColumnValue />
          </PatientNameContainer>
        </>
      )
    }
    return (
      <>
        <PatientNameContainer>
          <ColumnTitle>BP</ColumnTitle>
          {
            (
              (mostRecentDetail?.bpDiastolic ?? 0) > 0 &&
              (mostRecentDetail?.bpSystolic ?? 0) > 0
                ? (
                <ColumnValue
                  sx={{ ...(mostRecentDetail.bloodPressureOutOfRange && { color: 'red' }) }}
                >
                  {`${mostRecentDetail.bpSystolic ?? ''}/${mostRecentDetail.bpDiastolic ?? ''} mmHg`}
                </ColumnValue>
                  )
                : (
                <ColumnValue />
                  )
            )
          }
        </PatientNameContainer>
        <PatientNameContainer>
          <ColumnTitle>Pulse</ColumnTitle>
          {
            (mostRecentDetail.pulse ?? 0) > 0
              ? (
                <ColumnValue
                  sx={{ ...(mostRecentDetail.pulseOutOfRange && { color: '#FF0000' }) }}
                >
                  {`${mostRecentDetail.pulse ?? ''} BPM`}
                </ColumnValue>
                )
              : (
                <ColumnValue />
                )
          }
        </PatientNameContainer>
        <PatientNameContainer>
            <ColumnTitle>Resp.</ColumnTitle>
            {
              /* pretter-ignore */
              (mostRecentDetail.respiratoryRate ?? 0) > 0
                ? (
                  <ColumnValue
                    sx={{ ...(mostRecentDetail.respiratoryRateOutOfRange && { color: '#FF0000' }), marginTop: '-3px' }}
                  >
                    {`${mostRecentDetail.respiratoryRate ?? ''} per min`}
                  </ColumnValue>
                  )
                : (
                  <ColumnValue sx={{ marginTop: '-3px' }} />
                  )
            }
            </PatientNameContainer>
      </>
    )
  }
  const parseTime = (time: Date): Date => {
    const hour = time.getHours()
    let minute = time.getMinutes()

    if (minute < 15) {
      minute = 0
    } else if (minute < 30) {
      minute = 15
    } else if (minute < 45) {
      minute = 30
    } else {
      minute = 45
    }
    return new Date(time.getFullYear(), time.getMonth(), time.getDate(), hour, minute)
  }
  const handleAssignProvider = (providerId: number | undefined): void => {
    handleCloseProviderMenu()
    visit.providerAccountUserId = providerId
    visit.patient = undefined
    visit.chiefComplaint = undefined
    if (!visit.arrivalDateTime) {
      visit.arrivalDateTime = formatInUTC(new Date())
    }
    if (!visit.scheduleStart || !visit.scheduleEnd) {
      const scheduleStartTime = parseTime(addMinutes(new Date(), 15))
      visit.scheduleStart = formatInUTC(scheduleStartTime)
      visit.scheduleEnd = formatInUTC(addMinutes(scheduleStartTime, 15))
    }
    const response = updateVisitMutation.mutateAsync({ visitId: visit?.id!, visit })
    Promise.resolve(response)
      .then((res) => {
        refresh()
      })
      .catch((error) => { throw error })
      .finally(() => {
        fetchProcedure()
      })
  }

  return isLoading
    ? (
      <ProfilePanelContainer
        isNavOpen={isNavOpen}
        theme={theme}
        sx={{ padding: '0', alignItems: 'flex-end', borderBottom: '0' }}
      >
        <LinearProgress sx={{ width: '100%' }} />
      </ProfilePanelContainer>
      )
    : (
      <ProfilePanelContainer
        isNavOpen={isNavOpen}
        theme={theme}
      >
        <Menu
          id="lock-menu"
          anchorEl={menuAnchorEl}
          open={menuOpen}
          onClose={handleClose}
          sx={{ width: '360px', borderRadius: '8px' }}
          MenuListProps={{
            'aria-labelledby': 'lock-button',
            role: 'listbox'
          }}
        >
          <Link sx={{ textDecoration: 'none' }} href={`/profile/${patient?.id ?? 0}${isNavOpen ? '?previousNavOpen=true' : ''}`}>
            <MenuItem
              sx={{ width: '326px' }}
            >
              <MenuText>Patient Profile</MenuText>
            </MenuItem>
          </Link>
          <Divider sx={{ marginRight: '8px', marginLeft: '4px' }} />
          <ChipContainer>
            <Link href={`/profile/${patient?.id ?? 0}?${isNavOpen ? 'previousNavOpen=true&' : ''}tab=0`}>
              <StyledChip label="Demographics" />
            </Link>
            <Link href={`/profile/${patient?.id ?? 0}?${isNavOpen ? 'previousNavOpen=true&' : ''}tab=1`}>
              <StyledChip label="Insurance" />
            </Link>
            <Link href={`/profile/${patient?.id ?? 0}?${isNavOpen ? 'previousNavOpen=true&' : ''}tab=2`}>
              <StyledChip label="Medication" />
            </Link>
            <Link href={`/profile/${patient?.id ?? 0}?${isNavOpen ? 'previousNavOpen=true&' : ''}tab=3`}>
              <StyledChip label="History" />
            </Link>
            <Link href={`/profile/${patient?.id ?? 0}?${isNavOpen ? 'previousNavOpen=true&' : ''}tab=4`}>
              <StyledChip label="Orders & Procedures" />
            </Link>
            <Link href={`/profile/${patient?.id ?? 0}?${isNavOpen ? 'previousNavOpen=true&' : ''}tab=5`}>
              <StyledChip label="Visits" />
            </Link>
            <Link href={`/profile/${patient?.id ?? 0}?${isNavOpen ? 'previousNavOpen=true&' : ''}tab=6`}>
              <StyledChip label="Documents" />
            </Link>
            <Link href={`/profile/${patient?.id ?? 0}?${isNavOpen ? 'previousNavOpen=true&' : ''}tab=7`}>
              <StyledChip label="Billing" />
            </Link>
            <Link href={`/profile/${patient?.id ?? 0}?${isNavOpen ? 'previousNavOpen=true&' : ''}tab=8`}>
              <StyledChip label="Tasks" />
            </Link>
          </ChipContainer>
        </Menu>
        <Popover
          id="mouse-over-popover"
          sx={{
            pointerEvents: 'none',
            borderRadius: '12px'
          }}
          open={open}
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center'
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center'
          }}
          onClose={handlePopoverClose}
          disableRestoreFocus
        >
          <AllergyPopover
            allergies={
              showAllergies ? (visit?.noKnownDrugAllergies ? allergies : allergies.slice(1)) : complaintNames.slice(1)
            }
          />
        </Popover>
        <OuterColumn>
          <PatientPhotoNameContainer>
            <AvatarImage
              width={42}
              height={42}
              src={avatarUrl}
              alt="patient"
            />
            <PatientNameContainer sx={{ maxWidth: '250px' }}>
              <PatientNameText>{`${patient?.firstName ?? ''} ${patient?.lastName ?? ''
                }`}
                <Tooltip
                  title="Patient Notes"
                  arrow
                >
                  <IconButton
                    onClick={() => {
                      setStickyNotesOpen(true)
                    }}
                    sx={{ padding: 0, marginLeft: '8px' }}
                    size="large">
                    <FeedOutlinedIcon fontSize="small" sx={{ fill: patient?.stickyNote?.length ? '#2E3353' : '#2E335360' }} />
                  </IconButton>
                </Tooltip>
              </PatientNameText>
              <PatientInfoText>{`(${isDateOnlyValid(patient?.dateOfBirth) ? `${formatDateOnly(patient?.dateOfBirth)} - ${formatAgeWithRules(patient?.dateOfBirth)}` : ''})${!isEmpty(gender) ? ', ' : ''}${gender}`}</PatientInfoText>
            </PatientNameContainer>
          </PatientPhotoNameContainer>
          <PatientNameContainer>
            <ColumnTitle>Visit Type</ColumnTitle>
            <ColumnValue>
              {!isNaN(chiefComplaint?.visitTypeId!) &&
                visitTypes?.find(
                  (visitType) => visitType.id === chiefComplaint?.visitTypeId
                )?.name}
            </ColumnValue>
          </PatientNameContainer>
          <PatientNameContainer>
            <ColumnTitle>Reason for Visit</ColumnTitle>
            <AllergiesContainer>
            <Tooltip
              title={
                <>
                  {complaintNames[0] === 'Other' ? (chiefComplaint.notes ? `Other - ${chiefComplaint.notes}` : 'Other') : complaintNames[0]}
                </>
              }
              arrow
            >
              <TruncatedValue>{complaintNames[0] === 'Other' ? (chiefComplaint.notes ? `Other - ${chiefComplaint.notes}` : 'Other') : complaintNames[0]}</TruncatedValue>
              </Tooltip>
              {complaintNames.length > 1 && (
                <CountCircle
                  onMouseEnter={handlePopoverOpen}
                  onMouseLeave={handlePopoverClose}
                >
                  +{complaintNames.length - 1}
                </CountCircle>
              )}
            </AllergiesContainer>
          </PatientNameContainer>
          {
            bpPulseColumns()
          }
          <PatientNameContainer>
            <ColumnTitle>Weight</ColumnTitle>
            {
              /* pretter-ignore */
              vitals?.weight !== undefined && vitals?.weight > 0
                ? (
                  <ColumnValue
                    sx={{
                      ...(vitals?.weightOutOfRange && { color: '#FF0000' })
                    }}
                  >
                    {vitals?.isMetricWeight && vitals?.weight !== undefined && vitals?.weight > 0
                      ? `${vitals?.weight} kg (${(vitals?.weight * 2.20462).toFixed(0)} lbs)`
                      : `${vitals?.weight ?? ''} lbs (${(vitals?.weight / 2.20462).toFixed(2)} kg)`}
                  </ColumnValue>
                  )
                : (
                  <ColumnValue />
                  )
            }
          </PatientNameContainer>
          <PatientNameContainer>
            <ColumnTitle>Temp.</ColumnTitle>
            {
              (mostRecentDetail.temperature ?? 0) > 0
                ? (
                  <ColumnValue
                    sx={{ ...(mostRecentDetail.temperatureOutOfRange && { color: '#FF0000' }) }}
                  >
                    {`${mostRecentDetail.temperature ?? ''}°F`}
                  </ColumnValue>
                  )
                : (
                  <ColumnValue />
                  )
            }
          </PatientNameContainer>
          <PatientNameContainer>
            <ColumnTitle>Allergies</ColumnTitle>
            {
              (!isEmpty(allergies) || visit?.noKnownDrugAllergies)
                ? (
                  <AllergiesContainer>
                    {
                      visit?.noKnownDrugAllergies
                        ? (
                          <ColumnValue>NKDA</ColumnValue>
                          )
                        : (
                          <ColumnValue style={{ color: 'red' }}>{allergies[0].split(' - d')[0]}</ColumnValue>
                          )
                    }
                    {(allergies?.length > 1 || (visit?.noKnownDrugAllergies && allergies?.length > 0)) && (
                      <CountCircle
                        id="allergies"
                        onMouseEnter={handlePopoverOpen}
                        onMouseLeave={handlePopoverClose}
                      >
                        +{visit?.noKnownDrugAllergies ? allergies?.length : allergies?.length - 1}
                      </CountCircle>
                    )}
                  </AllergiesContainer>
                  )
                : (
                  <ColumnValue />
                  )
            }
          </PatientNameContainer>
          <PatientNameContainer>
            <ColumnTitle>O<sub>2</sub> Sat</ColumnTitle>
            {
              (mostRecentDetail.oxygenSaturation ?? 0) > 0
                ? (
                  <ColumnValue
                    sx={{ ...(mostRecentDetail.oxygenSaturationOutOfRange && { color: '#FF0000' }), marginTop: '-3px' }}
                  >
                    {`${mostRecentDetail.oxygenSaturation ?? ''}%`}
                  </ColumnValue>
                  )
                : (
                  <ColumnValue sx={{ marginTop: '-3px' }} />
                  )
            }
          </PatientNameContainer>
          {
            !isNaN(chiefComplaint?.visitTypeId!) &&
              (isWorkersComp || isOccMed)
              ? (
                <PatientNameContainer>
                  <ColumnTitle>Employer</ColumnTitle>
                  <ColumnValue>
                    {employerName}
                  </ColumnValue>
                </PatientNameContainer>
                )
              : !visit?.selfPay && (patient?.patientInsuranceList?.length ?? 0) > 0 && (
                <PatientNameContainer>
                  <ColumnTitle>Payer</ColumnTitle>
                  <ColumnValue>
                    {(patient?.patientInsuranceList as any[])[0]?.companyName ?? ''}
                  </ColumnValue>
                </PatientNameContainer>
                )}

          <PatientNameContainer>
            <ColumnTitle>MRN #</ColumnTitle>
            <ColumnValue>
              {patient?.id ?? ''}
            </ColumnValue>
          </PatientNameContainer>
        </OuterColumn>
        <OuterColumn sx={{ alignContent: 'center', alignItems: 'center' }}>
          <Tooltip title="Time since arrival in minutes">
            <VisitStatContainer>
              <ClockIcon />
              <ColumnValue>{timeSinceArrival}</ColumnValue>
            </VisitStatContainer>
          </Tooltip>
          <VisitStatContainer onMouseMove={() => setShouldShowAddProviderIcon(true)} onMouseLeave={() => setShouldShowAddProviderIcon(providerMenuOpen || false)}>
            <ProviderIcon />
            {provider ? <ColumnValue >{provider}</ColumnValue> : <SpaceValue>-</SpaceValue>}
            {shouldShowAddProviderIcon && <IconButton onClick={handleSelectProvider} size="large">
              <AddCircleOutlineRoundedIcon />
            </IconButton>}
          </VisitStatContainer>
          <VisitStatContainer>
            <RoomIcon />
            <ColumnValue>{room}</ColumnValue>
          </VisitStatContainer>
          <IconButton onClick={handleClickListItem} size="large">
            <MoreVertIcon
              sx={{ color: '#0543B0' }}
              fontSize={'medium'}
            />
          </IconButton>
        </OuterColumn>
        <Modal
          open={stickyNotesOpen}
          onClose={() => setStickyNotesOpen(false)}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
        >
          <StickyNotesModal
            patientId={patient?.id!}
            handleClose={() => {
              setStickyNotesOpen(false)
              refresh()
            }}
            getPatient={refresh}
          />
        </Modal>
        <Menu
          id="provider-menu"
          anchorEl={providerMenuAnchorEl}
          open={providerMenuOpen}
          onClose={handleCloseProviderMenu}
          sx={{ width: '200px', borderRadius: '8px', marginLeft: '-140px' }}
          MenuListProps={{
            'aria-labelledby': 'provider-button',
            role: 'listbox'
          }}
        >
          {providers.map((provider, index) => (
            <MenuItem
              key={index}
              sx={{ width: '216px', height: '42px' }}
              onClick={() => handleAssignProvider(provider.id)}
            >
              <MenuText>{`${provider?.firstName!} ${provider?.lastName!}`}</MenuText>
            </MenuItem>
          ))}
        </Menu>
      </ProfilePanelContainer>
      )
}

interface ProfilePanelProps {
  isNavOpen: boolean;
  visit: Visit;
  visitTypes: VisitType[];
  complaints: Complaint[];
  allergies: string[];
  provider: string;
  room: string;
  patient: Patient;
  employerName: string;
  chiefComplaint: ChiefComplaint;
  isLoading: boolean;
  refresh: any;
}

interface ProfilePanelContainerProps {
  isNavOpen: boolean;
  theme: Theme;
}
