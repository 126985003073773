import React from 'react'
import { useAuth } from '@clerk/nextjs'
import DownloadIcon from '@mui/icons-material/Download'
import { format } from 'date-fns'
import { Button, styled } from '@mui/material'
import { useQueryGetOccMedReportXLSXBlob } from '@/hook'
import { downloadFile } from '@/utility'

const StyledButton = styled(Button)({
  height: '32px',
  width: 'fit-content',
  maxWidth: '160px',
  minWidth: '74px',
  fontWeight: 600,
  fontSize: '14px',
  lineHeight: '20px',
})

export const OccMedReport = ({
  startDate,
  endDate,
  clinicId,
  isLoading,
  setIsLoading,
  disabled,
}: OccMedReportProps): JSX.Element => {
  const { getToken } = useAuth()

  const getOccMedReportXLSX = useQueryGetOccMedReportXLSXBlob(getToken)

  const fetchReport = (): void => {
    if (startDate && endDate) {
      setIsLoading(true)
      const formattedStartDate = format(startDate, 'yyyy-MM-dd')
      const formattedEndDate = format(endDate, 'yyyy-MM-dd')
      getOccMedReportXLSX.mutateAsync({
        startDate: formattedStartDate,
        endDate: formattedEndDate,
        clinicId
      })
      .then((reportBlob) => {
        downloadFile(reportBlob, `occ_med_report_${formattedStartDate}_${formattedEndDate}.xlsx`)
        setIsLoading(false)
      }).catch((err) => {
        console.log('Error', err)
        setIsLoading(false)
      })
    }
  }

  return (
    <StyledButton
      variant="contained"
      color="info"
      startIcon={<DownloadIcon />}
      disabled={
        !startDate || !endDate || startDate > endDate || isLoading || disabled
      }
      onClick={fetchReport}
    >
      Download XLSX
    </StyledButton>
  )
}

interface OccMedReportProps {
  startDate: Date | null;
  endDate: Date | null;
  clinicId?: number;
  isLoading: boolean;
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
  disabled?: boolean;
}
