import React from 'react'
import {
  Button,
  Checkbox,
  Dialog,
  Stack,
  StyledEngineProvider,
  ThemeProvider
} from '@mui/material'
import MaterialReactTable, { type MRT_ColumnFiltersState, type MRT_SortingState, type MRT_ColumnDef } from 'material-react-table'
import { type Patient, type Task } from '@/types'
import {
  useAccountUserStore,
  useClinicStore,
  useDebounce,
  useQueryGetAccountUsers,
  useQueryGetPatients,
  useQueryGetTaskPriority,
  useQueryGetTaskTypes,
  useTableTheme,
} from '@/hook'
import { useAuth, useUser } from '@clerk/nextjs'
import { type ITaskTableData } from '@/types/TaskLanding'
import { TabTitles, useQueryPaginateTasks, useTasksStore } from '@/hook/useTasks'
import { TypeCell } from './components/columnCells/TypeCell'
import { TitleCell } from './components/columnCells/TitleCell'
import { StatusCell } from './components/columnCells/StatusCell'
import { PriorityCell } from './components/columnCells/PriorityCell'
import { AssigneeCell } from './components/columnCells/AssigneeCell'
import { PatientCell } from './components/columnCells/PatientCell'
import { DocumentCell } from './components/columnCells/DocumentCell'
import { ActionsCell } from './components/columnCells/ActionsCell'
import {
  Badge,
  StyledTab,
  StyledTableText,
  Tabs,
} from './components/columnCells/styled'
import { formatDateOnly } from '@/utility'
import { CreateTaskModal } from '@/components/Task/CreateTaskModal'
import { TileSubtitle } from '../Charting'

interface TabInfo {
  title: TabTitles
  count: number
}

const initialTabInfo: TabInfo[] = [
  { title: TabTitles.ALL, count: 0 },
  { title: TabTitles.NEW, count: 0 },
  { title: TabTitles.IN_PROGRESS, count: 0 },
  { title: TabTitles.COMPLETED, count: 0 },
  { title: TabTitles.MY_TASKS, count: 0 }
]

export const TaskTable = ({
  patientId,
  editTask,
  hidePatientColumn = false
}: TaskTableProps): JSX.Element => {
  const tableTheme = useTableTheme()
  const { getToken } = useAuth()
  const { clinicId, clinics } = useClinicStore()
  const { data: userTaskTypes } = useQueryGetTaskTypes(getToken)
  const typeOptions = React.useMemo(
    () =>
      userTaskTypes?.map((type: any) => ({
        value: type.id,
        text: addSpaceBetweenLowerAndUpperCase(type.name ?? ''),
      })) ?? [],
    [userTaskTypes])

  const { data: userTaskPriority } = useQueryGetTaskPriority(getToken)

  const priorityOptions = React.useMemo(
    () =>
      userTaskPriority?.map((priority) => ({
        value: priority.id ?? -1,
        text: addSpaceBetweenLowerAndUpperCase(priority.name ?? ''),
      })) ?? [],
    [userTaskPriority]
  )

  const [selectedTab, setSelectedTab] = React.useState<TabTitles>(
    TabTitles.ALL
  )

  const [tabInfo, setTabInfo] = React.useState<TabInfo[]>(initialTabInfo)

  const [currentTask, setCurrentTask] = React.useState<number | null>(null)
  const [pagination, setPagination] = React.useState<{
    pageIndex: number
    pageSize: number
  }>({ pageIndex: 0, pageSize: 10 })
  const [columnFilters, setColumnFilters] = React.useState<MRT_ColumnFiltersState>([])
  const [isCreateTaskModalOpen, setIsCreateTaskModalOpen] =
    React.useState<boolean>(false)
  const [statusId, setStatusId] = React.useState<number | undefined>(undefined)
  const [accountUserId, setAccountUserId] = React.useState<number | undefined>(undefined)
  const [showAllClinics, setShowAllClinics] = React.useState<boolean>(false)
  const [titleFilter, setTitleFilter] = React.useState<string | undefined>(undefined)
  const titleFilterDebounce = useDebounce(titleFilter, 500)
  const [filters, setFilters] = React.useState<TaskFilters>({
    accountUserId: undefined,
    taskTypeId: undefined,
    taskStatusId: undefined,
    taskPriorityId: undefined,
    patientId: undefined,
  })
  const [sorting, setSorting] = React.useState<MRT_SortingState>([
    { id: 'priority', desc: true },
  ])
  const sortingMap: Record<string, 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8> = {
    default: 0,
    type: 1,
    title: 2,
    status: 3,
    priority: 4,
    account: 5,
    patient: 6,
    dateCompleted: 7,
    dateDue: 8
  }
  useQueryGetAccountUsers(getToken)
  const { data: patients } = useQueryGetPatients(clinicId, getToken)
  const { refetch } = useQueryPaginateTasks(
    getToken,
    selectedTab === TabTitles.MY_TASKS ? accountUserId : filters.accountUserId,
    showAllClinics ? undefined  : clinicId,
    patientId ?? filters.patientId,
    undefined,
    statusId,
    filters.taskTypeId,
    filters.taskPriorityId,
    titleFilterDebounce,
    undefined,
    undefined,
    sortingMap[sorting[0] ? sorting[0].id : 'priority'],
    !sorting[0]?.desc,
    pagination.pageIndex + 1,
    pagination.pageSize
  )

  const patientOptions = React.useMemo(
    () =>
      patients?.map((patient: Patient) => ({
        value: patient.id,
        text: `${patient.firstName ?? ''} ${patient.lastName ?? ''}`,
      })),
    [patients]
  )

  const {
    taskPage,
    isLoading,
    getCountForTab,
    getTasksForTab
  } = useTasksStore()
  const { accountUsers } = useAccountUserStore()

  const accountOptions = React.useMemo(
    () =>
      accountUsers.map((account) => ({
        value: account.id,
        text: `${account.firstName ?? ''} ${account.lastName ?? ''}`,
      })),
    [accountUsers]
  )

  const { user } = useUser()

  React.useEffect(() => {
  
    const currentAccountId = accountUsers.find(
      ({ username }) => username === user?.username
    )?.id

    if (selectedTab === TabTitles.MY_TASKS) {
      setAccountUserId(currentAccountId)
    } else {
      setAccountUserId(undefined)
    }
    if (selectedTab === TabTitles.COMPLETED) {
      setStatusId(3)
    } else if (selectedTab === TabTitles.IN_PROGRESS) {
      setStatusId(2)
    } else if (selectedTab === TabTitles.NEW) {
      setStatusId(1)
    } else {
      setStatusId(undefined)
    }
    setTabInfo([
      { title: TabTitles.ALL, count: taskPage.totalTasksPerCategory?.all ?? 0 },
      { title: TabTitles.NEW, count: taskPage.totalTasksPerCategory?.new ?? 0 },
      { title: TabTitles.IN_PROGRESS, count: taskPage.totalTasksPerCategory?.inProgress ?? 0 },
      { title: TabTitles.COMPLETED, count: taskPage.totalTasksPerCategory?.completed ?? 0 },
      { title: TabTitles.MY_TASKS, count: taskPage.totalTasksPerCategory?.myTasks ?? 0 }
    ])
  }, [accountUsers, getCountForTab, getTasksForTab, patients, selectedTab, taskPage, user?.username])

  React.useEffect(() => {
    if (!columnFilters.some(item => item.id === 'title')) {
      setTitleFilter(undefined)
    }

    const mappedObject = columnFilters.reduce<TaskFilters>((acc, { id, value }) => {
    switch (id) {
      case 'priority':
        acc.taskPriorityId = value as number
        break
      case 'status':
        acc.taskStatusId = value as number
        break
      case 'type':
        acc.taskTypeId = value as number
        break
      case 'account':
        acc.accountUserId = value as number
        break
      case 'patient':
        acc.patientId = value as number
        break
      case 'title':
        setTitleFilter(value as string)
        break
      default:

        break
    }
      return acc
    }, {
      taskPriorityId: undefined,
      taskStatusId: undefined,
      taskTypeId: undefined,
      accountUserId: undefined,
      patientId: undefined
    })

    setFilters(mappedObject)
  }, [columnFilters])

  React.useEffect(() => {
    refetch()
  }, [refetch, accountUserId, statusId, pagination.pageIndex, pagination.pageSize, sorting, filters, clinicId, titleFilterDebounce])

  const columns = React.useMemo<Array<MRT_ColumnDef<ITaskTableData>>>(
    () =>
      [
        {
          id: 'type',
          header: 'Type',
          enableSorting: true,
          enableFiltering: true,
          accessorFn: (row: ITaskTableData) => row.typeId,
          sortingFn: 'alphanumeric',
          size: 40,
          maxSize: 40,
          Cell: ({ row }: any) => <TypeCell row={row} />,
          filterSelectOptions: typeOptions,
          filterVariant: 'select' as 'select' | 'checkbox' | 'multi-select' | 'range' | 'range-slider' | 'text' | undefined

        },
        {
          id: 'title',
          header: 'Title',
          enableFiltering: true,
          accessorFn: (row: ITaskTableData) => row.title,
          size: 150,
          maxSize: 150,
          Cell: ({ row }: any) => <TitleCell row={row} />,
          filterVariant: 'text' as 'select' | 'checkbox' | 'multi-select' | 'range' | 'range-slider' | 'text' | undefined
        },
        {
          id: 'status',
          header: 'Status',
          size: 100,
          maxSize: 100,
          Cell: ({ row, cell }: any) => (
            <StatusCell
              row={row}
              cell={cell}
            />
          )
        },
        {
          id: 'priority',
          header: 'Priority',
          enableSorting: true,
          enableFiltering: true,
          accessorFn: (row: ITaskTableData) => row.priorityId,
          sortingFn: 'alphanumeric',
          size: 60,
          maxSize: 60,
          Cell: ({ row, cell }: any) => <PriorityCell row={row} cell={cell} />,
          filterSelectOptions: priorityOptions,
          filterVariant: 'select' as 'select' | 'checkbox' | 'multi-select' | 'range' | 'range-slider' | 'text' | undefined
        },
        {
          id: 'account',
          header: 'Assignee',
          enableSorting: true,
          accessorFn: (row: ITaskTableData) =>
            `${row.account?.firstName! ?? ''} ${row.account?.lastName! ?? ''}`,
          sortingFn: 'alphanumeric',
          size: 140,
          maxSize: 140,
          Cell: ({ row }: any) => {
            return <AssigneeCell taskId={row.original.id} />
          },
          filterSelectOptions: accountOptions,
          filterVariant: 'select' as 'select' | 'checkbox' | 'multi-select' | 'range' | 'range-slider' | 'text' | undefined
        },
        {
          id: 'patient',
          header: 'Patient',
          enableSorting: true,
          accessorFn: (row: ITaskTableData) =>
            `${row.patientDetails?.firstName! ?? ''} ${row.patientDetails?.lastName! ?? ''
            }`,
          sortingFn: 'alphanumeric',
          size: 170,
          maxSize: 170,
          filterSelectOptions: patientOptions,
          filterVariant: 'select' as 'select' | 'checkbox' | 'multi-select' | 'range' | 'range-slider' | 'text' | undefined,
          Cell: ({ row, cell }: any) => <PatientCell row={row} cell={cell} />,
        },
        {
          id: 'dateCompleted',
          header: 'Completed Date',
          size: 100,
          maxSize: 100,
          Cell: ({ row }: any) => (
            <StyledTableText
              sx={{ fontSize: '14px', color: '#454545', width: '100px' }}
            >
              {row?.original?.dateCompleted
                ? formatDateOnly(row?.original?.dateCompleted)
                : '-'}
            </StyledTableText>
          ),
        },
        {
          id: 'dateDue',
          header: 'Due Date',
          accessorFn: (row: ITaskTableData) => row.dateDue,
          size: 100,
          maxSize: 100,
          enableSorting: true,  // Sorting enabled
          enableColumnFilter: false, // Filtering disabled
          Cell: ({ row }: any) => (
            <StyledTableText
              sx={{ fontSize: '14px', color: '#454545', width: '100px' }}
            >
              {row?.original?.dateDue
                ? formatDateOnly(row?.original?.dateDue)
                : '-'}
            </StyledTableText>
          ),
        },
        {
          id: 'notes',
          header: 'Notes',
          size: 200,
          maxSize: 200,
          Cell: ({ row }: any) => {
            return (
              <Stack
                direction='row'
                sx={{
                  alignItems: 'center',
                }}
              >
                <StyledTableText sx={{ width: '200px' }}>
                  {row?.original?.notes ?? ''}
                </StyledTableText>
              </Stack>
            )
          },
        },
        {
          id: 'document',
          header: 'Document',
          size: 100,
          maxSize: 100,
          Cell: ({ row }: any) => (
            <DocumentCell row={row} />
          ),
        },
        {
          header: 'Actions',
          id: 'actions',
          enableSorting: false,
          maxSize: 100,
          Cell: ({ row }: any) => (
            <ActionsCell row={row} />
          ),
        },
      ]
        .filter(
          (col) =>
            col.id !== 'dateCompleted' || selectedTab === TabTitles.MY_TASKS
        )
        .filter((col) => !hidePatientColumn || col.id !== 'patient'),
    [selectedTab, hidePatientColumn]
  )

  return (
    <>
      {clinics.length > 1 &&
        (<Stack direction={'row'} justifyContent={'flex-end'}>
          <Stack direction={'row'} alignContent={'center'}>
            <Checkbox
              checked={showAllClinics}
              onChange={() => setShowAllClinics(!showAllClinics)}
              data-testid={'show-all-clinics-checkbox'}
              sx={{ padding: 0, pl: 1 }}
            />
            <TileSubtitle>Show All Clinics</TileSubtitle>
          </Stack>
        </Stack>)}
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={tableTheme}>
          <MaterialReactTable
            columns={columns}
            data={taskPage.items?.map((task: Task): ITaskTableData => {
              return ({
                ...task,
                notes: task.notes ?? '',
                account: accountUsers.find(
                  ({ id }) => id === task.accountUserId
                ) ?? {},
                creator: accountUsers.find(({ id }) => id === task.createdById) ?? {},
                patientDetails: patients?.find(({ id }) => id === task.patientId) ?? {},
                documentId: task.documentId ?? undefined,
                dateAssigned: task.dateAssigned,
                dateCompleted: task.dateCompleted,
                dateDue: task.dateDue,
                id: task.id ?? -1,
                priorityId: task.priorityId ?? -1,
                title: task.title,
                statusId: task.statusId ?? -1,
                typeId: task.typeId ?? -1,

              }
              )
            }

            ) ?? []}
            enableColumnActions={false}
            enableRowActions={false}
            enableGlobalFilterModes
            enableSorting
            manualFiltering
            enableSortingRemoval={false}
            enableColumnFilters
            renderTopToolbarCustomActions={() => {
              return (
                <Tabs>
                  {tabInfo.map((item, index): any => (
                    <StyledTab
                      key={item.title}
                      className={`${selectedTab === item.title ? 'selected' : ''
                        }`}
                      onClick={() => setSelectedTab(item.title)}
                    >
                      {item.title}
                      <Badge dark={selectedTab === item.title}>
                        {item.count}
                      </Badge>
                    </StyledTab>
                  ))}
                  <Button
                    variant='contained'
                    onClick={() => {
                      setCurrentTask(null)
                      setIsCreateTaskModalOpen(true)
                    }}
                    sx={{ marginLeft: '4px' }}
                  >
                    Add Task
                  </Button>
                </Tabs>
              )
            }}
            globalFilterModeOptions={['fuzzy', 'contains']}
            state={{ isLoading, pagination, sorting, columnFilters }}
            rowCount={taskPage?.totalCount ?? 0}
            manualPagination
            manualSorting
            onSortingChange={setSorting}
            onPaginationChange={setPagination}
            onColumnFiltersChange={setColumnFilters}
            enableDensityToggle={false}
            muiTablePaginationProps={{
              rowsPerPageOptions: [5, 10, 20],
              showFirstButton: false,
              showLastButton: false,
            }}
            muiTableBodyRowProps={({ row }: any) => ({
              sx: {
                cursor: 'pointer',
              },
              onClick: () => {
                editTask(row?.original?.id ?? -1)
              },
            })}
          />
          <Dialog
            open={isCreateTaskModalOpen}
            sx={{ borderRadius: '12px' }}
            maxWidth={false}
            onClose={() => {
              setIsCreateTaskModalOpen(false)
            }}
          >
            <CreateTaskModal
              defaultTask={{ patientId }}
              currentTask={currentTask ?? -1}
              setClose={() => {
                setIsCreateTaskModalOpen(false)
              }}
            />
          </Dialog>
        </ThemeProvider>
      </StyledEngineProvider>
    </>
  )
}

interface TaskTableProps {
  patientId: number | null
  editTask: (taskId: number) => void
  hidePatientColumn?: boolean
  taskData?: Task[]
}

interface TaskFilters {
  accountUserId?: number | undefined
  taskTypeId?: number | undefined
  taskStatusId?: number | undefined
  taskPriorityId?: number | undefined
  patientId?: number | undefined
  dueDateStart?:  | undefined
}

function addSpaceBetweenLowerAndUpperCase(input: string): string {
  return input.replace(/([a-z])([A-Z])/g, '$1 $2')
}
