import React from 'react'
import { useProcedureStore } from '@/hook'
import { SummaryTile } from '@/components'
import { format } from 'date-fns'
import { isEmpty, formatInLocal } from '@/utility'
import {
  type AccountUser,
  type CPTModifier,
  type Patient,
  type ProcedureType,
  type SubsectionValue,
  type Summary
} from '@/types'
import { buildCPTSections } from '@/helpers'
import { useAuth } from '@clerk/nextjs'

export const ProcedureSummary = ({
  procedureTypes,
  CPTModifiers,
  users,
  patient,
  patientLabs,
  inFocus
}: ProcedureSummaryProps): JSX.Element => {
  const { getToken } = useAuth()
  const { procedure } = useProcedureStore()
  const [open, setOpen] = React.useState<boolean>(false)

  const sectionName = 'Orders & Procedures'
  const [summary, setSummary] = React.useState<Summary>({
    sectionName,
    sections: [],
  })

  React.useEffect(() => {
    const buildProcedureSummary = async (): Promise<void> => {
      let proceduresSummary: SubsectionValue[] = []
      let consentString = ''

      const formatDate = (): string => {
        if (
          isEmpty(procedure?.entireSectionUpdatedAt) ||
          procedure?.entireSectionUpdatedAt === '0001-01-01T00:00:00+00:00'
        ) {
          return format(new Date(Date.now()), 'h:mm a')
        } else {
          return format(
            new Date(formatInLocal(procedure?.entireSectionUpdatedAt ?? '')),
            'h:mm a'
          )
        }
      }

      if (procedure === null) {
        consentString = 'None'
      } else if (
        (procedure?.writtenConsent === undefined ||
          procedure?.writtenConsent === null) &&
        (procedure?.verbalConsent === undefined ||
          procedure?.verbalConsent === null)
      ) {
        consentString = 'None'
      } else if (procedure?.writtenConsent! && procedure?.verbalConsent!) {
        consentString = 'Verbal and Written'
      } else if (procedure?.verbalConsent!) {
        consentString = 'Verbal'
      } else if (procedure?.writtenConsent!) {
        consentString = 'Written'
      } else {
        consentString = 'None'
      }

      if (procedure !== null) {
        const mods = [
          '99202',
          '99203',
          '99204',
          '99205',
          '99212',
          '99213',
          '99214',
          '99215',
        ]

        if (
          procedure?.writtenConsent !== undefined ||
          procedure?.verbalConsent !== undefined
        ) {
          proceduresSummary.push({ Consent: `${consentString}` })
        }

        if (
          procedure?.visitClinicalProcedureList !== null &&
          procedure?.visitClinicalProcedureList !== undefined
        ) {
          const newSections = await buildCPTSections(
            procedure?.visitClinicalProcedureList.filter(
              (cpt: any) =>
                cpt.clinicalProcedureId !== null &&
                cpt.clinicalProcedureId !== undefined &&
                cpt?.clinicalProcedureId !== 193 &&
                cpt?.cptCodeOther !== null &&
                cpt?.cptCodeOther !== undefined &&
                !mods.includes(cpt?.cptCodeOther)
            ) ?? [],
            proceduresSummary,
            getToken,
            procedureTypes,
            CPTModifiers
          ).catch((error) => {
            throw error
          })
          proceduresSummary = newSections
        }

        if (!isEmpty(procedure?.details)) {
          proceduresSummary.push({ Details: `${procedure?.details ?? ''}` })
        }
        if (!isEmpty(procedure?.results)) {
          proceduresSummary.push({ Results: `${procedure?.results ?? ''}` })
        }
        if (
          procedure?.providerAccountUserId !== null &&
          procedure?.providerAccountUserId !== undefined &&
          procedure?.providerAccountUserId !== 0
        ) {
          proceduresSummary.push({
            Provider: `${
              users?.find(
                (user) => user.id === procedure?.providerAccountUserId
              )?.firstName ?? ''
            } ${
              users?.find(
                (user) => user.id === procedure?.providerAccountUserId
              )?.lastName ?? ''
            } ${formatDate() === '' ? '' : `(${formatDate()})`}`,
          })
        }
        if (procedure !== null && patient?.id) {
          try {
            const ordersSummary = patientLabs
              .map((labName) => `${labName as string}`)
              .join(', ')
            proceduresSummary.push({ 'Sent Orders': ordersSummary })
          } catch (error) {
            console.error('Error fetching lab orders:', error)
            proceduresSummary.push({ 'Sent Orders': 'Error fetching orders' })
          }
        }
      }
      const sections = [{ name: '', value: proceduresSummary }]

      const procedureSummary: Summary = {
        sectionName: 'Orders & Procedures',
        sections,
      }

      setSummary(procedureSummary)
    }

    buildProcedureSummary()
  }, [
    procedure,
    CPTModifiers,
    patient,
    patientLabs,
    procedureTypes,
    users,
    inFocus,
  ])

  return (
    <SummaryTile
      summary={summary}
      isOpen={open}
      setIsOpen={setOpen}
      title="procedures"
      inFocus={inFocus}
    />
  )
}

interface ProcedureSummaryProps {
  procedureTypes: ProcedureType[]
  CPTModifiers: CPTModifier[]
  users: AccountUser[]
  patient: Patient
  patientLabs: any[]
  inFocus: boolean
}
