import React from 'react'
import MaterialReactTable from 'material-react-table'
import { Stack } from '@mui/system'
import { Chip } from '@mui/material'
import { Space } from '../TaskTable/components/columnCells'
import { styled } from '@mui/material/styles'
import { isEmpty } from '@/utility'

const Badge = styled('div')(({ dark = false }: BadgeStyleProps): any => ({
  color: dark ? 'white' : '#252D63',
  backgroundColor: dark ? '#0543B0' : '#E5EBFF',
  minWidth: '22px',
  height: '22px',
  borderRadius: '50%',
  fontSize: '12px',
  fontWeight: 600,
  marginLeft: '5px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center'
}))

const Tabs = styled('div')({
  display: 'flex',
  marginLeft: '-0.5rem'
})

const StyledTab = styled('div')(({ theme }) => ({
  display: 'flex',
  textTransform: 'none',
  fontSize: '14px',
  lineHeight: '20px',
  marginRight: theme.spacing(1),
  color: theme.palette.black,
  borderRadius: '6px 6px 0 0',
  border: '1px solid #CFD8FD',
  borderBottom: '0px',
  padding: '8px 12px 8px 12px',
  fontWeight: '600',
  gap: '8px',
  cursor: 'pointer',
  '&.selected': {
    color: theme.palette.primary.main,
    backgroundColor: '#CFD8FD',
    borderTop: `3px solid ${theme.palette.primary.main}`
  }
}))

const FilterTag = (props: any): JSX.Element => {
  const { title, type, onRemoveFilter } = props

  return (
    <Chip
      sx={{
        height: '32px',
        background: '#E1E8F5',
        borderRadius: '16px',
        fontSize: '13px',
        lineHeight: '18px',
        color: '#000000',
        mr: 1
      }}
      label={`${type as string}: ${
        (typeof title === 'string'
          ? title
          : typeof title === 'object'
          ? title?.label ?? ''
          : '') as string
      }`}
      onDelete={() => onRemoveFilter(type)}
    />
  )
}

export const UnifiedTable = ({
  columns,
  filteredTableData,
  tableInstanceRef,
  isTableLoading,
  isColumnFixed,
  pinnedColumn,
  selectedTab,
  tabList,
  handleDoubleClickTable,
  handleChangeTab,
  tabsInfo,
  filterList,
  enableColumnResizing,
  handleRemoveFilter,
  enableTopToolbar = true,
  enableRowActions = false,
  tableType = '',
  pagination,
  setPagination,
  sorting = [],
  setSorting = () => {},
  totalCount
}: UnifiedTableProps): JSX.Element => {
  return (
    <MaterialReactTable
      columns={columns}
      data={filteredTableData ?? []}
      enableColumnActions={false}
      enableRowActions={enableRowActions}
      positionActionsColumn={tableType === 'past' ? 'last' : 'first'}
      enableGlobalFilterModes
      enableTopToolbar={enableTopToolbar}
      enableDensityToggle={false}
      globalFilterModeOptions={['fuzzy', 'contains']}
      tableInstanceRef={tableInstanceRef}
      enableColumnResizing={enableColumnResizing}
      rowCount={totalCount || filteredTableData?.length || 0}
      enableSortingRemoval={false}
      manualPagination
      manualSorting
      state={{
        isLoading: isTableLoading,
        columnOrder: [],
        columnPinning: {
          ...(isColumnFixed && { left: pinnedColumn })
        },
        pagination,
        sorting
      }}
      initialState={{
        sorting:
          tableType === ''
            ? selectedTab === tabList[0]?.title
              ? []
              : [{ id: 'time_since_arrival', desc: false }]
            : tableType === 'past'
            ? [{ id: 'visitID', desc: false }]
            : [],
        pagination: {
          pageSize: pagination.pageSize,
          pageIndex: pagination.pageIndex
        }
      }}
      muiTablePaginationProps={{
        rowsPerPageOptions: [10, 20, 50],
        showFirstButton: false,
        showLastButton: false
      }}
      muiTableBodyRowProps={({ row }: any) => ({
        onDoubleClick: () => handleDoubleClickTable(row),
        sx: { cursor: 'pointer' }
      })}
      muiTableHeadRowProps={{
        sx: { backgroundColor: '#EBF0F9' }
      }}
      renderTopToolbarCustomActions={() => {
        return (
          <Stack
            flexDirection={'row'}
            justifyContent={'space-between'}
            flex={1}
          >
            <Tabs>
              {tabsInfo.map((item: TabInfo, index: number): any => (
                <StyledTab
                  key={item.title}
                  className={`${selectedTab === item.title ? 'selected' : ''}`}
                  onClick={() => handleChangeTab(item.title)}
                >
                  {item.title}
                  <Badge dark={selectedTab === item.title}>{item.count}</Badge>
                </StyledTab>
              ))}
              <Space />
            </Tabs>
            <Stack
              direction={'row'}
              alignItems={'center'}
            >
              {filterList.map((item: any): any =>
                !isEmpty(item.value) ? (
                  <FilterTag
                    type={item.category}
                    title={item.label}
                    key={item.category}
                    onRemoveFilter={handleRemoveFilter}
                  />
                ) : (
                  <></>
                )
              )}
            </Stack>
          </Stack>
        )
      }}
      muiLinearProgressProps={({ isTopToolbar }) => ({
        sx: {
          display: isTopToolbar ? 'block' : 'none',
          animation: isTableLoading ? 'shimmer 1.5s infinite linear' : 'none'
        }
      })}
      onPaginationChange={setPagination}
      onSortingChange={setSorting}
      displayColumnDefOptions={{
        'mrt-row-actions': {
          header: 'Actions',
          minSize: 100
        }
      }}
    />
  )
}

interface UnifiedTableProps {
  columns: any
  filteredTableData: any
  tableInstanceRef: any
  isTableLoading: boolean
  isColumnFixed: boolean
  pinnedColumn: string[]
  selectedTab: string
  tabList: any
  pagination: any
  handleDoubleClickTable: any
  handleChangeTab: any
  tabsInfo: TabInfo[]
  filterList: any
  enableColumnResizing: boolean
  handleRemoveFilter: any
  setPagination: any
  enableTopToolbar?: boolean
  enableRowActions?: boolean
  tableType?: TableType
  sorting?: any
  setSorting?: any
  totalCount?: number
}

interface BadgeStyleProps {
  dark?: boolean
}

interface TabInfo {
  title: string
  count: number
}

export type TableType = '' | 'past' | 'upcoming'
